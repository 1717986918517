/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
.capitalize {
  text-transform: uppercase; }

.mat-input-invalid .mat-input-placeholder {
  color: red; }

.mat-input-invalid .mat-input-ripple {
  background-color: red; }

.mat-icon-xs {
  font-size: 16px;
  height: 24px;
  width: 16px;
  color: grey; }

html, body {
  height: 100%;
  font-family: "Raleway",sans-serif; }

a.active {
  background-color: gray; }

.text-primary {
  color: #caaa58; }

.formulaire-reservation {
  overflow: inherit !important;
  border-radius: 3px;
  padding: 17px 10px; }

.nopadr {
  padding-right: 0; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }

.form-date .input-icon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  border-right: 0; }

.form-date .input-date {
  display: block;
  width: 100%;
  height: 34px !important;
  padding: 6px 12px;
  line-height: 1.42857;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: none;
  border-radius: 0 4px 4px 0;
  font-family: Roboto,sans-serif;
  font-size: 13px; }

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

.nopadl {
  padding-left: 0; }

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 * @param target Which kind of high contrast setting to target. Defaults to `active`, can be
 *    `white-on-black` or `black-on-white`.
 */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1); }

.dark-theme .mat-option {
  color: white; }
  .dark-theme .mat-option:hover:not(.mat-option-disabled), .dark-theme .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(255, 255, 255, 0.04); }
  .dark-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(255, 255, 255, 0.04); }
  .dark-theme .mat-option.mat-active {
    background: rgba(255, 255, 255, 0.04);
    color: white; }
  .dark-theme .mat-option.mat-option-disabled {
    color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #bd9947; }

.dark-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black; }

.dark-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ed2828; }

.dark-theme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7); }
  .dark-theme .mat-pseudo-checkbox::after {
    color: #303030; }

.dark-theme .mat-pseudo-checkbox-checked,
.dark-theme .mat-pseudo-checkbox-indeterminate,
.dark-theme .mat-accent .mat-pseudo-checkbox-checked,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: black; }

.dark-theme .mat-primary .mat-pseudo-checkbox-checked,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #bd9947; }

.dark-theme .mat-warn .mat-pseudo-checkbox-checked,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ed2828; }

.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868; }

.dark-theme .mat-app-background, .dark-theme.mat-app-background {
  background-color: #303030;
  color: white; }

.mat-theme-loaded-marker {
  display: none; }

.dark-theme .mat-autocomplete-panel {
  background: #424242;
  color: white; }
  .dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: #424242; }
    .dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: white; }

.dark-theme .mat-badge-content {
  color: white;
  background: #bd9947; }

.dark-theme .mat-badge-accent .mat-badge-content {
  background: black;
  color: white; }

.dark-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ed2828; }

.dark-theme .mat-badge {
  position: relative; }

.dark-theme .mat-badge-hidden .mat-badge-content {
  display: none; }

.dark-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.dark-theme .mat-badge-content.mat-badge-active {
  transform: none; }

.dark-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }
  @media screen and (-ms-high-contrast: active) {
    .dark-theme .mat-badge-small .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.dark-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.dark-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.dark-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .dark-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.dark-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .dark-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.dark-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }
  @media screen and (-ms-high-contrast: active) {
    .dark-theme .mat-badge-medium .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.dark-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.dark-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.dark-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .dark-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.dark-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .dark-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.dark-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }
  @media screen and (-ms-high-contrast: active) {
    .dark-theme .mat-badge-large .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.dark-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.dark-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.dark-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .dark-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.dark-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .dark-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.dark-theme .mat-bottom-sheet-container {
  background: #424242;
  color: white; }

.dark-theme .mat-button, .dark-theme .mat-icon-button, .dark-theme .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .dark-theme .mat-button.mat-primary, .dark-theme .mat-icon-button.mat-primary, .dark-theme .mat-stroked-button.mat-primary {
    color: #bd9947; }
  .dark-theme .mat-button.mat-accent, .dark-theme .mat-icon-button.mat-accent, .dark-theme .mat-stroked-button.mat-accent {
    color: black; }
  .dark-theme .mat-button.mat-warn, .dark-theme .mat-icon-button.mat-warn, .dark-theme .mat-stroked-button.mat-warn {
    color: #ed2828; }
  .dark-theme .mat-button.mat-primary[disabled], .dark-theme .mat-button.mat-accent[disabled], .dark-theme .mat-button.mat-warn[disabled], .dark-theme .mat-button[disabled][disabled], .dark-theme .mat-icon-button.mat-primary[disabled], .dark-theme .mat-icon-button.mat-accent[disabled], .dark-theme .mat-icon-button.mat-warn[disabled], .dark-theme .mat-icon-button[disabled][disabled], .dark-theme .mat-stroked-button.mat-primary[disabled], .dark-theme .mat-stroked-button.mat-accent[disabled], .dark-theme .mat-stroked-button.mat-warn[disabled], .dark-theme .mat-stroked-button[disabled][disabled] {
    color: rgba(255, 255, 255, 0.3); }
  .dark-theme .mat-button.mat-primary .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: rgba(189, 153, 71, 0.12); }
  .dark-theme .mat-button.mat-accent .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-button.mat-warn .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: rgba(237, 40, 40, 0.12); }
  .dark-theme .mat-button[disabled] .mat-button-focus-overlay, .dark-theme .mat-icon-button[disabled] .mat-button-focus-overlay, .dark-theme .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .dark-theme .mat-button.mat-primary .mat-ripple-element, .dark-theme .mat-icon-button.mat-primary .mat-ripple-element, .dark-theme .mat-stroked-button.mat-primary .mat-ripple-element {
    background-color: rgba(189, 153, 71, 0.1); }
  .dark-theme .mat-button.mat-accent .mat-ripple-element, .dark-theme .mat-icon-button.mat-accent .mat-ripple-element, .dark-theme .mat-stroked-button.mat-accent .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.1); }
  .dark-theme .mat-button.mat-warn .mat-ripple-element, .dark-theme .mat-icon-button.mat-warn .mat-ripple-element, .dark-theme .mat-stroked-button.mat-warn .mat-ripple-element {
    background-color: rgba(237, 40, 40, 0.1); }

.dark-theme .mat-flat-button, .dark-theme .mat-raised-button, .dark-theme .mat-fab, .dark-theme .mat-mini-fab {
  color: white;
  background-color: #424242; }
  .dark-theme .mat-flat-button.mat-primary, .dark-theme .mat-raised-button.mat-primary, .dark-theme .mat-fab.mat-primary, .dark-theme .mat-mini-fab.mat-primary {
    color: white; }
  .dark-theme .mat-flat-button.mat-accent, .dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
    color: white; }
  .dark-theme .mat-flat-button.mat-warn, .dark-theme .mat-raised-button.mat-warn, .dark-theme .mat-fab.mat-warn, .dark-theme .mat-mini-fab.mat-warn {
    color: white; }
  .dark-theme .mat-flat-button.mat-primary[disabled], .dark-theme .mat-flat-button.mat-accent[disabled], .dark-theme .mat-flat-button.mat-warn[disabled], .dark-theme .mat-flat-button[disabled][disabled], .dark-theme .mat-raised-button.mat-primary[disabled], .dark-theme .mat-raised-button.mat-accent[disabled], .dark-theme .mat-raised-button.mat-warn[disabled], .dark-theme .mat-raised-button[disabled][disabled], .dark-theme .mat-fab.mat-primary[disabled], .dark-theme .mat-fab.mat-accent[disabled], .dark-theme .mat-fab.mat-warn[disabled], .dark-theme .mat-fab[disabled][disabled], .dark-theme .mat-mini-fab.mat-primary[disabled], .dark-theme .mat-mini-fab.mat-accent[disabled], .dark-theme .mat-mini-fab.mat-warn[disabled], .dark-theme .mat-mini-fab[disabled][disabled] {
    color: rgba(255, 255, 255, 0.3); }
  .dark-theme .mat-flat-button.mat-primary, .dark-theme .mat-raised-button.mat-primary, .dark-theme .mat-fab.mat-primary, .dark-theme .mat-mini-fab.mat-primary {
    background-color: #bd9947; }
  .dark-theme .mat-flat-button.mat-accent, .dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
    background-color: black; }
  .dark-theme .mat-flat-button.mat-warn, .dark-theme .mat-raised-button.mat-warn, .dark-theme .mat-fab.mat-warn, .dark-theme .mat-mini-fab.mat-warn {
    background-color: #ed2828; }
  .dark-theme .mat-flat-button.mat-primary[disabled], .dark-theme .mat-flat-button.mat-accent[disabled], .dark-theme .mat-flat-button.mat-warn[disabled], .dark-theme .mat-flat-button[disabled][disabled], .dark-theme .mat-raised-button.mat-primary[disabled], .dark-theme .mat-raised-button.mat-accent[disabled], .dark-theme .mat-raised-button.mat-warn[disabled], .dark-theme .mat-raised-button[disabled][disabled], .dark-theme .mat-fab.mat-primary[disabled], .dark-theme .mat-fab.mat-accent[disabled], .dark-theme .mat-fab.mat-warn[disabled], .dark-theme .mat-fab[disabled][disabled], .dark-theme .mat-mini-fab.mat-primary[disabled], .dark-theme .mat-mini-fab.mat-accent[disabled], .dark-theme .mat-mini-fab.mat-warn[disabled], .dark-theme .mat-mini-fab[disabled][disabled] {
    background-color: rgba(255, 255, 255, 0.12); }
  .dark-theme .mat-flat-button.mat-primary .mat-ripple-element, .dark-theme .mat-raised-button.mat-primary .mat-ripple-element, .dark-theme .mat-fab.mat-primary .mat-ripple-element, .dark-theme .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .dark-theme .mat-flat-button.mat-accent .mat-ripple-element, .dark-theme .mat-raised-button.mat-accent .mat-ripple-element, .dark-theme .mat-fab.mat-accent .mat-ripple-element, .dark-theme .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .dark-theme .mat-flat-button.mat-warn .mat-ripple-element, .dark-theme .mat-raised-button.mat-warn .mat-ripple-element, .dark-theme .mat-fab.mat-warn .mat-ripple-element, .dark-theme .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.dark-theme .mat-icon-button.mat-primary .mat-ripple-element {
  background-color: rgba(189, 153, 71, 0.2); }

.dark-theme .mat-icon-button.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.2); }

.dark-theme .mat-icon-button.mat-warn .mat-ripple-element {
  background-color: rgba(237, 40, 40, 0.2); }

.dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5); }
  .dark-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-button-toggle-disabled {
  background-color: black;
  color: rgba(255, 255, 255, 0.3); }
  .dark-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #424242; }

.dark-theme .mat-card {
  background: #424242;
  color: white; }

.dark-theme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-checkbox-checkmark {
  fill: #303030; }

.dark-theme .mat-checkbox-checkmark-path {
  stroke: #303030 !important; }
  @media screen and (-ms-high-contrast: black-on-white) {
    .dark-theme .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.dark-theme .mat-checkbox-mixedmark {
  background-color: #303030; }

.dark-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #bd9947; }

.dark-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black; }

.dark-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ed2828; }

.dark-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .dark-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868; }

.dark-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868; }

.dark-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: #686868; }

@media screen and (-ms-high-contrast: active) {
  .dark-theme .mat-checkbox-disabled {
    opacity: 0.5; } }

@media screen and (-ms-high-contrast: active) {
  .dark-theme .mat-checkbox-background {
    background: none; } }

.dark-theme .mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(189, 153, 71, 0.26); }

.dark-theme .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.26); }

.dark-theme .mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(237, 40, 40, 0.26); }

.dark-theme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white; }
  .dark-theme .mat-chip.mat-standard-chip .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .dark-theme .mat-chip.mat-standard-chip .mat-chip-remove:hover {
    opacity: 0.54; }

.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #bd9947;
  color: white; }
  .dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove:hover {
    opacity: 0.54; }

.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ed2828;
  color: white; }
  .dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove:hover {
    opacity: 0.54; }

.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: black;
  color: white; }
  .dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove:hover {
    opacity: 0.54; }

.dark-theme .mat-table {
  background: #424242; }

.dark-theme .mat-table thead, .dark-theme .mat-table tbody, .dark-theme .mat-table tfoot,
.dark-theme mat-header-row, .dark-theme mat-row, .dark-theme mat-footer-row,
.dark-theme [mat-header-row], .dark-theme [mat-row], .dark-theme [mat-footer-row],
.dark-theme .mat-table-sticky {
  background: inherit; }

.dark-theme mat-row, .dark-theme mat-header-row, .dark-theme mat-footer-row,
.dark-theme th.mat-header-cell, .dark-theme td.mat-cell, .dark-theme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-cell, .dark-theme .mat-footer-cell {
  color: white; }

.dark-theme .mat-calendar-arrow {
  border-top-color: white; }

.dark-theme .mat-datepicker-toggle,
.dark-theme .mat-datepicker-content .mat-calendar-next-button,
.dark-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white; }

.dark-theme .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-calendar-body-cell-content {
  color: white;
  border-color: transparent; }

.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.dark-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.dark-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(255, 255, 255, 0.04); }

.dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-calendar-body-selected {
  background-color: #bd9947;
  color: white; }

.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(189, 153, 71, 0.4); }

.dark-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.dark-theme .mat-datepicker-content {
  background-color: #424242;
  color: white; }
  .dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: black;
    color: white; }
  .dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(0, 0, 0, 0.4); }
  .dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }
  .dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #ed2828;
    color: white; }
  .dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(237, 40, 40, 0.4); }
  .dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.dark-theme .mat-datepicker-toggle-active {
  color: #bd9947; }
  .dark-theme .mat-datepicker-toggle-active.mat-accent {
    color: black; }
  .dark-theme .mat-datepicker-toggle-active.mat-warn {
    color: #ed2828; }

.dark-theme .mat-dialog-container {
  background: #424242;
  color: white; }

.dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-expansion-panel {
  background: #424242;
  color: white; }

.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(255, 255, 255, 0.04); }

@media (hover: none) {
  .dark-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
.mat-expansion-panel-header:hover {
    background: #424242; } }

.dark-theme .mat-expansion-panel-header-title {
  color: white; }

.dark-theme .mat-expansion-panel-header-description,
.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(255, 255, 255, 0.3); }
  .dark-theme .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .dark-theme .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.dark-theme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-hint {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #bd9947; }
  .dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: black; }
  .dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #ed2828; }

.dark-theme .mat-focused .mat-form-field-required-marker {
  color: black; }

.dark-theme .mat-form-field-ripple {
  background-color: white; }

.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #bd9947; }
  .dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: black; }
  .dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #ed2828; }

.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ed2828; }
  .dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #ed2828; }

.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ed2828; }

.dark-theme .mat-error {
  color: #ed2828; }

.dark-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.dark-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.dark-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1); }

.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05); }

.dark-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.dark-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white; }

.dark-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #bd9947; }

.dark-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: black; }

.dark-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ed2828; }

.dark-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ed2828; }

.dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15); }

.dark-theme .mat-icon.mat-primary {
  color: #bd9947; }

.dark-theme .mat-icon.mat-accent {
  color: black; }

.dark-theme .mat-icon.mat-warn {
  color: #ed2828; }

.dark-theme .mat-input-element:disabled {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-input-element {
  caret-color: #bd9947; }
  .dark-theme .mat-input-element::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .dark-theme .mat-input-element::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .dark-theme .mat-input-element::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .dark-theme .mat-input-element:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-accent .mat-input-element {
  caret-color: black; }

.dark-theme .mat-warn .mat-input-element,
.dark-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #ed2828; }

.dark-theme .mat-list .mat-list-item, .dark-theme .mat-nav-list .mat-list-item, .dark-theme .mat-selection-list .mat-list-item {
  color: white; }

.dark-theme .mat-list .mat-list-option, .dark-theme .mat-nav-list .mat-list-option, .dark-theme .mat-selection-list .mat-list-option {
  color: white; }

.dark-theme .mat-list .mat-subheader, .dark-theme .mat-nav-list .mat-subheader, .dark-theme .mat-selection-list .mat-subheader {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-list-item-disabled {
  background-color: black; }

.dark-theme .mat-list-option:hover, .dark-theme .mat-list-option.mat-list-item-focus,
.dark-theme .mat-nav-list .mat-list-item:hover,
.dark-theme .mat-nav-list .mat-list-item.mat-list-item-focus {
  background: rgba(255, 255, 255, 0.04); }

.dark-theme .mat-menu-panel {
  background: #424242; }

.dark-theme .mat-menu-item {
  background: transparent;
  color: white; }
  .dark-theme .mat-menu-item[disabled], .dark-theme .mat-menu-item[disabled]::after {
    color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-menu-item .mat-icon:not([color]),
.dark-theme .mat-menu-item-submenu-trigger::after {
  color: white; }

.dark-theme .mat-menu-item:hover:not([disabled]),
.dark-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.dark-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04); }

.dark-theme .mat-paginator {
  background: #424242; }

.dark-theme .mat-paginator,
.dark-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-paginator-decrement,
.dark-theme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white; }

.dark-theme .mat-paginator-first,
.dark-theme .mat-paginator-last {
  border-top: 2px solid white; }

.dark-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.dark-theme .mat-icon-button[disabled] .mat-paginator-increment,
.dark-theme .mat-icon-button[disabled] .mat-paginator-first,
.dark-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-progress-bar-background {
  fill: #dac48a; }

.dark-theme .mat-progress-bar-buffer {
  background-color: #dac48a; }

.dark-theme .mat-progress-bar-fill::after {
  background-color: #bd9947; }

.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b3b3b3; }

.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b3b3b3; }

.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: black; }

.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #e70202; }

.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #e70202; }

.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ed2828; }

.dark-theme .mat-progress-spinner circle, .dark-theme .mat-spinner circle {
  stroke: #bd9947; }

.dark-theme .mat-progress-spinner.mat-accent circle, .dark-theme .mat-spinner.mat-accent circle {
  stroke: black; }

.dark-theme .mat-progress-spinner.mat-warn circle, .dark-theme .mat-spinner.mat-warn circle {
  stroke: #ed2828; }

.dark-theme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd9947; }

.dark-theme .mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #bd9947; }

.dark-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(189, 153, 71, 0.26); }

.dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: black; }

.dark-theme .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: black; }

.dark-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.26); }

.dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ed2828; }

.dark-theme .mat-radio-button.mat-warn .mat-radio-inner-circle {
  background-color: #ed2828; }

.dark-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(237, 40, 40, 0.26); }

.dark-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-select-content, .dark-theme .mat-select-panel-done-animating {
  background: #424242; }

.dark-theme .mat-select-value {
  color: white; }

.dark-theme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #bd9947; }

.dark-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: black; }

.dark-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ed2828; }

.dark-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ed2828; }

.dark-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-drawer-container {
  background-color: #303030;
  color: white; }

.dark-theme .mat-drawer {
  background-color: #424242;
  color: white; }
  .dark-theme .mat-drawer.mat-drawer-push {
    background-color: #424242; }

.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6); }

.dark-theme .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: gray; }

.dark-theme .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(128, 128, 128, 0.5); }

.dark-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-slide-toggle .mat-ripple-element {
  background-color: rgba(128, 128, 128, 0.12); }

.dark-theme .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #e5d5ac; }

.dark-theme .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(229, 213, 172, 0.5); }

.dark-theme .mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-slide-toggle.mat-primary .mat-ripple-element {
  background-color: rgba(229, 213, 172, 0.12); }

.dark-theme .mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f58181; }

.dark-theme .mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(245, 129, 129, 0.5); }

.dark-theme .mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-slide-toggle.mat-warn .mat-ripple-element {
  background-color: rgba(245, 129, 129, 0.12); }

.dark-theme .mat-disabled .mat-slide-toggle-thumb {
  background-color: #424242; }

.dark-theme .mat-disabled .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-slide-toggle-thumb {
  background-color: #bdbdbd; }

.dark-theme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-primary .mat-slider-track-fill,
.dark-theme .mat-primary .mat-slider-thumb,
.dark-theme .mat-primary .mat-slider-thumb-label {
  background-color: #bd9947; }

.dark-theme .mat-primary .mat-slider-thumb-label-text {
  color: white; }

.dark-theme .mat-accent .mat-slider-track-fill,
.dark-theme .mat-accent .mat-slider-thumb,
.dark-theme .mat-accent .mat-slider-thumb-label {
  background-color: black; }

.dark-theme .mat-accent .mat-slider-thumb-label-text {
  color: white; }

.dark-theme .mat-warn .mat-slider-track-fill,
.dark-theme .mat-warn .mat-slider-thumb,
.dark-theme .mat-warn .mat-slider-thumb-label {
  background-color: #ed2828; }

.dark-theme .mat-warn .mat-slider-thumb-label-text {
  color: white; }

.dark-theme .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.2); }

.dark-theme .mat-slider:hover .mat-slider-track-background,
.dark-theme .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-slider-disabled .mat-slider-track-background,
.dark-theme .mat-slider-disabled .mat-slider-track-fill,
.dark-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white; }

.dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent; }

.dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3); }

.dark-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7); }

.dark-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent); }

.dark-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent); }

.dark-theme .mat-step-header.cdk-keyboard-focused, .dark-theme .mat-step-header.cdk-program-focused, .dark-theme .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04); }

.dark-theme .mat-step-header .mat-step-label,
.dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-step-header .mat-step-icon {
  background-color: #bd9947;
  color: white; }

.dark-theme .mat-step-header .mat-step-icon-not-touched {
  background-color: rgba(255, 255, 255, 0.5);
  color: white; }

.dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white; }

.dark-theme .mat-stepper-horizontal, .dark-theme .mat-stepper-vertical {
  background-color: #424242; }

.dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-sort-header-arrow {
  color: #c6c6c6; }

.dark-theme .mat-tab-nav-bar,
.dark-theme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12); }

.dark-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.dark-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none; }

.dark-theme .mat-tab-label, .dark-theme .mat-tab-link {
  color: white; }
  .dark-theme .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-tab-header-pagination-chevron {
  border-color: white; }

.dark-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5); }

.dark-theme .mat-tab-group[class*='mat-background-'] .mat-tab-header,
.dark-theme .mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 196, 138, 0.3); }

.dark-theme .mat-tab-group.mat-primary .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #bd9947; }

.dark-theme .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 179, 179, 0.3); }

.dark-theme .mat-tab-group.mat-accent .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: black; }

.dark-theme .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white; }

.dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(231, 2, 2, 0.3); }

.dark-theme .mat-tab-group.mat-warn .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ed2828; }

.dark-theme .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 196, 138, 0.3); }

.dark-theme .mat-tab-group.mat-background-primary .mat-tab-header, .dark-theme .mat-tab-group.mat-background-primary .mat-tab-links, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #bd9947; }

.dark-theme .mat-tab-group.mat-background-primary .mat-tab-label, .dark-theme .mat-tab-group.mat-background-primary .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.dark-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.dark-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.dark-theme .mat-tab-group.mat-background-primary .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 179, 179, 0.3); }

.dark-theme .mat-tab-group.mat-background-accent .mat-tab-header, .dark-theme .mat-tab-group.mat-background-accent .mat-tab-links, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: black; }

.dark-theme .mat-tab-group.mat-background-accent .mat-tab-label, .dark-theme .mat-tab-group.mat-background-accent .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white; }
  .dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.dark-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white; }

.dark-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.dark-theme .mat-tab-group.mat-background-accent .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(231, 2, 2, 0.3); }

.dark-theme .mat-tab-group.mat-background-warn .mat-tab-header, .dark-theme .mat-tab-group.mat-background-warn .mat-tab-links, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #ed2828; }

.dark-theme .mat-tab-group.mat-background-warn .mat-tab-label, .dark-theme .mat-tab-group.mat-background-warn .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.dark-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.dark-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.dark-theme .mat-tab-group.mat-background-warn .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.dark-theme .mat-toolbar {
  background: #212121;
  color: white; }
  .dark-theme .mat-toolbar.mat-primary {
    background: #bd9947;
    color: white; }
  .dark-theme .mat-toolbar.mat-accent {
    background: black;
    color: white; }
  .dark-theme .mat-toolbar.mat-warn {
    background: #ed2828;
    color: white; }
  .dark-theme .mat-toolbar .mat-form-field-underline,
  .dark-theme .mat-toolbar .mat-form-field-ripple,
  .dark-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .dark-theme .mat-toolbar .mat-form-field-label,
  .dark-theme .mat-toolbar .mat-focused .mat-form-field-label,
  .dark-theme .mat-toolbar .mat-select-value,
  .dark-theme .mat-toolbar .mat-select-arrow,
  .dark-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .dark-theme .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.dark-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.dark-theme .mat-tree {
  background: #424242; }

.dark-theme .mat-tree-node {
  color: white; }

.dark-theme .mat-snack-bar-container {
  background: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.dark-theme .mat-simple-snackbar-action {
  color: inherit; }

.light-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1); }

.light-theme .mat-option {
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-option:hover:not(.mat-option-disabled), .light-theme .mat-option:focus:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .light-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, 0.04); }
  .light-theme .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #bd9947; }

.light-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black; }

.light-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ed2828; }

.light-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54); }
  .light-theme .mat-pseudo-checkbox::after {
    color: #fafafa; }

.light-theme .mat-pseudo-checkbox-checked,
.light-theme .mat-pseudo-checkbox-indeterminate,
.light-theme .mat-accent .mat-pseudo-checkbox-checked,
.light-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: black; }

.light-theme .mat-primary .mat-pseudo-checkbox-checked,
.light-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #bd9947; }

.light-theme .mat-warn .mat-pseudo-checkbox-checked,
.light-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ed2828; }

.light-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.light-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0; }

.light-theme .mat-app-background, .light-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.mat-theme-loaded-marker {
  display: none; }

.light-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: white; }
    .light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
      color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-badge-content {
  color: white;
  background: #bd9947; }

.light-theme .mat-badge-accent .mat-badge-content {
  background: black;
  color: white; }

.light-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ed2828; }

.light-theme .mat-badge {
  position: relative; }

.light-theme .mat-badge-hidden .mat-badge-content {
  display: none; }

.light-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.light-theme .mat-badge-content.mat-badge-active {
  transform: none; }

.light-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px; }
  @media screen and (-ms-high-contrast: active) {
    .light-theme .mat-badge-small .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.light-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px; }

.light-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px; }

.light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px; }

[dir='rtl'] .light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px; }

.light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px; }

[dir='rtl'] .light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px; }

.light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px; }

[dir='rtl'] .light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px; }

.light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px; }

[dir='rtl'] .light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px; }

.light-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px; }
  @media screen and (-ms-high-contrast: active) {
    .light-theme .mat-badge-medium .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.light-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px; }

.light-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px; }

.light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px; }

[dir='rtl'] .light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px; }

.light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px; }

[dir='rtl'] .light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px; }

.light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px; }

[dir='rtl'] .light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px; }

.light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px; }

[dir='rtl'] .light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px; }

.light-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px; }
  @media screen and (-ms-high-contrast: active) {
    .light-theme .mat-badge-large .mat-badge-content {
      outline: solid 1px;
      border-radius: 0; } }

.light-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px; }

.light-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px; }

.light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px; }

[dir='rtl'] .light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px; }

.light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px; }

[dir='rtl'] .light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px; }

.light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px; }

[dir='rtl'] .light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px; }

.light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px; }

[dir='rtl'] .light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px; }

.light-theme .mat-bottom-sheet-container {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-button, .light-theme .mat-icon-button, .light-theme .mat-stroked-button {
  color: inherit;
  background: transparent; }
  .light-theme .mat-button.mat-primary, .light-theme .mat-icon-button.mat-primary, .light-theme .mat-stroked-button.mat-primary {
    color: #bd9947; }
  .light-theme .mat-button.mat-accent, .light-theme .mat-icon-button.mat-accent, .light-theme .mat-stroked-button.mat-accent {
    color: black; }
  .light-theme .mat-button.mat-warn, .light-theme .mat-icon-button.mat-warn, .light-theme .mat-stroked-button.mat-warn {
    color: #ed2828; }
  .light-theme .mat-button.mat-primary[disabled], .light-theme .mat-button.mat-accent[disabled], .light-theme .mat-button.mat-warn[disabled], .light-theme .mat-button[disabled][disabled], .light-theme .mat-icon-button.mat-primary[disabled], .light-theme .mat-icon-button.mat-accent[disabled], .light-theme .mat-icon-button.mat-warn[disabled], .light-theme .mat-icon-button[disabled][disabled], .light-theme .mat-stroked-button.mat-primary[disabled], .light-theme .mat-stroked-button.mat-accent[disabled], .light-theme .mat-stroked-button.mat-warn[disabled], .light-theme .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .light-theme .mat-button.mat-primary .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: rgba(189, 153, 71, 0.12); }
  .light-theme .mat-button.mat-accent .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }
  .light-theme .mat-button.mat-warn .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: rgba(237, 40, 40, 0.12); }
  .light-theme .mat-button[disabled] .mat-button-focus-overlay, .light-theme .mat-icon-button[disabled] .mat-button-focus-overlay, .light-theme .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent; }
  .light-theme .mat-button.mat-primary .mat-ripple-element, .light-theme .mat-icon-button.mat-primary .mat-ripple-element, .light-theme .mat-stroked-button.mat-primary .mat-ripple-element {
    background-color: rgba(189, 153, 71, 0.1); }
  .light-theme .mat-button.mat-accent .mat-ripple-element, .light-theme .mat-icon-button.mat-accent .mat-ripple-element, .light-theme .mat-stroked-button.mat-accent .mat-ripple-element {
    background-color: rgba(0, 0, 0, 0.1); }
  .light-theme .mat-button.mat-warn .mat-ripple-element, .light-theme .mat-icon-button.mat-warn .mat-ripple-element, .light-theme .mat-stroked-button.mat-warn .mat-ripple-element {
    background-color: rgba(237, 40, 40, 0.1); }

.light-theme .mat-flat-button, .light-theme .mat-raised-button, .light-theme .mat-fab, .light-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white; }
  .light-theme .mat-flat-button.mat-primary, .light-theme .mat-raised-button.mat-primary, .light-theme .mat-fab.mat-primary, .light-theme .mat-mini-fab.mat-primary {
    color: white; }
  .light-theme .mat-flat-button.mat-accent, .light-theme .mat-raised-button.mat-accent, .light-theme .mat-fab.mat-accent, .light-theme .mat-mini-fab.mat-accent {
    color: white; }
  .light-theme .mat-flat-button.mat-warn, .light-theme .mat-raised-button.mat-warn, .light-theme .mat-fab.mat-warn, .light-theme .mat-mini-fab.mat-warn {
    color: white; }
  .light-theme .mat-flat-button.mat-primary[disabled], .light-theme .mat-flat-button.mat-accent[disabled], .light-theme .mat-flat-button.mat-warn[disabled], .light-theme .mat-flat-button[disabled][disabled], .light-theme .mat-raised-button.mat-primary[disabled], .light-theme .mat-raised-button.mat-accent[disabled], .light-theme .mat-raised-button.mat-warn[disabled], .light-theme .mat-raised-button[disabled][disabled], .light-theme .mat-fab.mat-primary[disabled], .light-theme .mat-fab.mat-accent[disabled], .light-theme .mat-fab.mat-warn[disabled], .light-theme .mat-fab[disabled][disabled], .light-theme .mat-mini-fab.mat-primary[disabled], .light-theme .mat-mini-fab.mat-accent[disabled], .light-theme .mat-mini-fab.mat-warn[disabled], .light-theme .mat-mini-fab[disabled][disabled] {
    color: rgba(0, 0, 0, 0.26); }
  .light-theme .mat-flat-button.mat-primary, .light-theme .mat-raised-button.mat-primary, .light-theme .mat-fab.mat-primary, .light-theme .mat-mini-fab.mat-primary {
    background-color: #bd9947; }
  .light-theme .mat-flat-button.mat-accent, .light-theme .mat-raised-button.mat-accent, .light-theme .mat-fab.mat-accent, .light-theme .mat-mini-fab.mat-accent {
    background-color: black; }
  .light-theme .mat-flat-button.mat-warn, .light-theme .mat-raised-button.mat-warn, .light-theme .mat-fab.mat-warn, .light-theme .mat-mini-fab.mat-warn {
    background-color: #ed2828; }
  .light-theme .mat-flat-button.mat-primary[disabled], .light-theme .mat-flat-button.mat-accent[disabled], .light-theme .mat-flat-button.mat-warn[disabled], .light-theme .mat-flat-button[disabled][disabled], .light-theme .mat-raised-button.mat-primary[disabled], .light-theme .mat-raised-button.mat-accent[disabled], .light-theme .mat-raised-button.mat-warn[disabled], .light-theme .mat-raised-button[disabled][disabled], .light-theme .mat-fab.mat-primary[disabled], .light-theme .mat-fab.mat-accent[disabled], .light-theme .mat-fab.mat-warn[disabled], .light-theme .mat-fab[disabled][disabled], .light-theme .mat-mini-fab.mat-primary[disabled], .light-theme .mat-mini-fab.mat-accent[disabled], .light-theme .mat-mini-fab.mat-warn[disabled], .light-theme .mat-mini-fab[disabled][disabled] {
    background-color: rgba(0, 0, 0, 0.12); }
  .light-theme .mat-flat-button.mat-primary .mat-ripple-element, .light-theme .mat-raised-button.mat-primary .mat-ripple-element, .light-theme .mat-fab.mat-primary .mat-ripple-element, .light-theme .mat-mini-fab.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .light-theme .mat-flat-button.mat-accent .mat-ripple-element, .light-theme .mat-raised-button.mat-accent .mat-ripple-element, .light-theme .mat-fab.mat-accent .mat-ripple-element, .light-theme .mat-mini-fab.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }
  .light-theme .mat-flat-button.mat-warn .mat-ripple-element, .light-theme .mat-raised-button.mat-warn .mat-ripple-element, .light-theme .mat-fab.mat-warn .mat-ripple-element, .light-theme .mat-mini-fab.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, 0.1); }

.light-theme .mat-icon-button.mat-primary .mat-ripple-element {
  background-color: rgba(189, 153, 71, 0.2); }

.light-theme .mat-icon-button.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.2); }

.light-theme .mat-icon-button.mat-warn .mat-ripple-element {
  background-color: rgba(237, 40, 40, 0.2); }

.light-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38); }
  .light-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-button-toggle-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.26); }
  .light-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd; }

.light-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-checkbox-checkmark {
  fill: #fafafa; }

.light-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important; }
  @media screen and (-ms-high-contrast: black-on-white) {
    .light-theme .mat-checkbox-checkmark-path {
      stroke: #000 !important; } }

.light-theme .mat-checkbox-mixedmark {
  background-color: #fafafa; }

.light-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #bd9947; }

.light-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black; }

.light-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ed2828; }

.light-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .light-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0; }

.light-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0; }

.light-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: #b0b0b0; }

@media screen and (-ms-high-contrast: active) {
  .light-theme .mat-checkbox-disabled {
    opacity: 0.5; } }

@media screen and (-ms-high-contrast: active) {
  .light-theme .mat-checkbox-background {
    background: none; } }

.light-theme .mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(189, 153, 71, 0.26); }

.light-theme .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(237, 40, 40, 0.26); }

.light-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4; }
  .light-theme .mat-chip.mat-standard-chip .mat-chip-remove:hover {
    opacity: 0.54; }

.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #bd9947;
  color: white; }
  .light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove:hover {
    opacity: 0.54; }

.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ed2828;
  color: white; }
  .light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove:hover {
    opacity: 0.54; }

.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: black;
  color: white; }
  .light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: white;
    opacity: 0.4; }
  .light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove:hover {
    opacity: 0.54; }

.light-theme .mat-table {
  background: white; }

.light-theme .mat-table thead, .light-theme .mat-table tbody, .light-theme .mat-table tfoot,
.light-theme mat-header-row, .light-theme mat-row, .light-theme mat-footer-row,
.light-theme [mat-header-row], .light-theme [mat-row], .light-theme [mat-footer-row],
.light-theme .mat-table-sticky {
  background: inherit; }

.light-theme mat-row, .light-theme mat-header-row, .light-theme mat-footer-row,
.light-theme th.mat-header-cell, .light-theme td.mat-cell, .light-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-cell, .light-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-datepicker-toggle,
.light-theme .mat-datepicker-content .mat-calendar-next-button,
.light-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12); }

.light-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent; }

.light-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.light-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.light-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(0, 0, 0, 0.04); }

.light-theme .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18); }

.light-theme .mat-calendar-body-selected {
  background-color: #bd9947;
  color: white; }

.light-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(189, 153, 71, 0.4); }

.light-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white; }

.light-theme .mat-datepicker-content {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: black;
    color: white; }
  .light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(0, 0, 0, 0.4); }
  .light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }
  .light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #ed2828;
    color: white; }
  .light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(237, 40, 40, 0.4); }
  .light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px white; }

.light-theme .mat-datepicker-toggle-active {
  color: #bd9947; }
  .light-theme .mat-datepicker-toggle-active.mat-accent {
    color: black; }
  .light-theme .mat-datepicker-toggle-active.mat-warn {
    color: #ed2828; }

.light-theme .mat-dialog-container {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused, .light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused, .light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(0, 0, 0, 0.04); }

@media (hover: none) {
  .light-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true'])
.mat-expansion-panel-header:hover {
    background: white; } }

.light-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-expansion-panel-header-description,
.light-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-expansion-panel-header[aria-disabled='true'] {
  color: rgba(0, 0, 0, 0.26); }
  .light-theme .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title,
  .light-theme .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    color: inherit; }

.light-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6); }

.light-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6); }

.light-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #bd9947; }
  .light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: black; }
  .light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #ed2828; }

.light-theme .mat-focused .mat-form-field-required-marker {
  color: black; }

.light-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #bd9947; }
  .light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: black; }
  .light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #ed2828; }

.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ed2828; }
  .light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #ed2828; }

.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ed2828; }

.light-theme .mat-error {
  color: #ed2828; }

.light-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.light-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.light-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42); }

.light-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x; }

.light-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04); }

.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02); }

.light-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42); }

.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent; }

.light-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #bd9947; }

.light-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: black; }

.light-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ed2828; }

.light-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ed2828; }

.light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06); }

.light-theme .mat-icon.mat-primary {
  color: #bd9947; }

.light-theme .mat-icon.mat-accent {
  color: black; }

.light-theme .mat-icon.mat-warn {
  color: #ed2828; }

.light-theme .mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-input-element {
  caret-color: #bd9947; }
  .light-theme .mat-input-element::placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .light-theme .mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .light-theme .mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }
  .light-theme .mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.42); }

.light-theme .mat-accent .mat-input-element {
  caret-color: black; }

.light-theme .mat-warn .mat-input-element,
.light-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #ed2828; }

.light-theme .mat-list .mat-list-item, .light-theme .mat-nav-list .mat-list-item, .light-theme .mat-selection-list .mat-list-item {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-list .mat-list-option, .light-theme .mat-nav-list .mat-list-option, .light-theme .mat-selection-list .mat-list-option {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-list .mat-subheader, .light-theme .mat-nav-list .mat-subheader, .light-theme .mat-selection-list .mat-subheader {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-list-item-disabled {
  background-color: #eeeeee; }

.light-theme .mat-list-option:hover, .light-theme .mat-list-option.mat-list-item-focus,
.light-theme .mat-nav-list .mat-list-item:hover,
.light-theme .mat-nav-list .mat-list-item.mat-list-item-focus {
  background: rgba(0, 0, 0, 0.04); }

.light-theme .mat-menu-panel {
  background: white; }

.light-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-menu-item[disabled], .light-theme .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-menu-item .mat-icon:not([color]),
.light-theme .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-menu-item:hover:not([disabled]),
.light-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.light-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.light-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04); }

.light-theme .mat-paginator {
  background: white; }

.light-theme .mat-paginator,
.light-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-paginator-decrement,
.light-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54); }

.light-theme .mat-paginator-first,
.light-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54); }

.light-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.light-theme .mat-icon-button[disabled] .mat-paginator-increment,
.light-theme .mat-icon-button[disabled] .mat-paginator-first,
.light-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-progress-bar-background {
  fill: #dac48a; }

.light-theme .mat-progress-bar-buffer {
  background-color: #dac48a; }

.light-theme .mat-progress-bar-fill::after {
  background-color: #bd9947; }

.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b3b3b3; }

.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b3b3b3; }

.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: black; }

.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #e70202; }

.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #e70202; }

.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ed2828; }

.light-theme .mat-progress-spinner circle, .light-theme .mat-spinner circle {
  stroke: #bd9947; }

.light-theme .mat-progress-spinner.mat-accent circle, .light-theme .mat-spinner.mat-accent circle {
  stroke: black; }

.light-theme .mat-progress-spinner.mat-warn circle, .light-theme .mat-spinner.mat-warn circle {
  stroke: #ed2828; }

.light-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #bd9947; }

.light-theme .mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #bd9947; }

.light-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(189, 153, 71, 0.26); }

.light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: black; }

.light-theme .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: black; }

.light-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ed2828; }

.light-theme .mat-radio-button.mat-warn .mat-radio-inner-circle {
  background-color: #ed2828; }

.light-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(237, 40, 40, 0.26); }

.light-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-select-content, .light-theme .mat-select-panel-done-animating {
  background: white; }

.light-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42); }

.light-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54); }

.light-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12); }

.light-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #bd9947; }

.light-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: black; }

.light-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ed2828; }

.light-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ed2828; }

.light-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-drawer.mat-drawer-push {
    background-color: white; }

.light-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6); }

.light-theme .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: black; }

.light-theme .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.5); }

.light-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.light-theme .mat-slide-toggle .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #caaa58; }

.light-theme .mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(202, 170, 88, 0.5); }

.light-theme .mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.light-theme .mat-slide-toggle.mat-primary .mat-ripple-element {
  background-color: rgba(202, 170, 88, 0.12); }

.light-theme .mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ea0202; }

.light-theme .mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(234, 2, 2, 0.5); }

.light-theme .mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06); }

.light-theme .mat-slide-toggle.mat-warn .mat-ripple-element {
  background-color: rgba(234, 2, 2, 0.12); }

.light-theme .mat-disabled .mat-slide-toggle-thumb {
  background-color: #bdbdbd; }

.light-theme .mat-disabled .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.1); }

.light-theme .mat-slide-toggle-thumb {
  background-color: #fafafa; }

.light-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-primary .mat-slider-track-fill,
.light-theme .mat-primary .mat-slider-thumb,
.light-theme .mat-primary .mat-slider-thumb-label {
  background-color: #bd9947; }

.light-theme .mat-primary .mat-slider-thumb-label-text {
  color: white; }

.light-theme .mat-accent .mat-slider-track-fill,
.light-theme .mat-accent .mat-slider-thumb,
.light-theme .mat-accent .mat-slider-thumb-label {
  background-color: black; }

.light-theme .mat-accent .mat-slider-thumb-label-text {
  color: white; }

.light-theme .mat-warn .mat-slider-track-fill,
.light-theme .mat-warn .mat-slider-thumb,
.light-theme .mat-warn .mat-slider-thumb-label {
  background-color: #ed2828; }

.light-theme .mat-warn .mat-slider-thumb-label-text {
  color: white; }

.light-theme .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.2); }

.light-theme .mat-slider:hover .mat-slider-track-background,
.light-theme .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-slider-disabled .mat-slider-track-background,
.light-theme .mat-slider-disabled .mat-slider-track-fill,
.light-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.light-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent; }

.light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26); }

.light-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7); }

.light-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.light-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent); }

.light-theme .mat-step-header.cdk-keyboard-focused, .light-theme .mat-step-header.cdk-program-focused, .light-theme .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04); }

.light-theme .mat-step-header .mat-step-label,
.light-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-step-header .mat-step-icon {
  background-color: #bd9947;
  color: white; }

.light-theme .mat-step-header .mat-step-icon-not-touched {
  background-color: rgba(0, 0, 0, 0.38);
  color: white; }

.light-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-stepper-horizontal, .light-theme .mat-stepper-vertical {
  background-color: white; }

.light-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12); }

.light-theme .mat-sort-header-arrow {
  color: #757575; }

.light-theme .mat-tab-nav-bar,
.light-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.light-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.light-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none; }

.light-theme .mat-tab-label, .light-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38); }

.light-theme .mat-tab-group[class*='mat-background-'] .mat-tab-header,
.light-theme .mat-tab-nav-bar[class*='mat-background-'] {
  border-bottom: none;
  border-top: none; }

.light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 196, 138, 0.3); }

.light-theme .mat-tab-group.mat-primary .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #bd9947; }

.light-theme .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white; }

.light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 179, 179, 0.3); }

.light-theme .mat-tab-group.mat-accent .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: black; }

.light-theme .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white; }

.light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(231, 2, 2, 0.3); }

.light-theme .mat-tab-group.mat-warn .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ed2828; }

.light-theme .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white; }

.light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(218, 196, 138, 0.3); }

.light-theme .mat-tab-group.mat-background-primary .mat-tab-header, .light-theme .mat-tab-group.mat-background-primary .mat-tab-links, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
  background-color: #bd9947; }

.light-theme .mat-tab-group.mat-background-primary .mat-tab-label, .light-theme .mat-tab-group.mat-background-primary .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white; }
  .light-theme .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.light-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white; }

.light-theme .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.light-theme .mat-tab-group.mat-background-primary .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 179, 179, 0.3); }

.light-theme .mat-tab-group.mat-background-accent .mat-tab-header, .light-theme .mat-tab-group.mat-background-accent .mat-tab-links, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
  background-color: black; }

.light-theme .mat-tab-group.mat-background-accent .mat-tab-label, .light-theme .mat-tab-group.mat-background-accent .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white; }
  .light-theme .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.light-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white; }

.light-theme .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.light-theme .mat-tab-group.mat-background-accent .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(231, 2, 2, 0.3); }

.light-theme .mat-tab-group.mat-background-warn .mat-tab-header, .light-theme .mat-tab-group.mat-background-warn .mat-tab-links, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
  background-color: #ed2828; }

.light-theme .mat-tab-group.mat-background-warn .mat-tab-label, .light-theme .mat-tab-group.mat-background-warn .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white; }
  .light-theme .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, 0.4); }

.light-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white; }

.light-theme .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4); }

.light-theme .mat-tab-group.mat-background-warn .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12); }

.light-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87); }
  .light-theme .mat-toolbar.mat-primary {
    background: #bd9947;
    color: white; }
  .light-theme .mat-toolbar.mat-accent {
    background: black;
    color: white; }
  .light-theme .mat-toolbar.mat-warn {
    background: #ed2828;
    color: white; }
  .light-theme .mat-toolbar .mat-form-field-underline,
  .light-theme .mat-toolbar .mat-form-field-ripple,
  .light-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
    background-color: currentColor; }
  .light-theme .mat-toolbar .mat-form-field-label,
  .light-theme .mat-toolbar .mat-focused .mat-form-field-label,
  .light-theme .mat-toolbar .mat-select-value,
  .light-theme .mat-toolbar .mat-select-arrow,
  .light-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
    color: inherit; }
  .light-theme .mat-toolbar .mat-input-element {
    caret-color: currentColor; }

.light-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9); }

.light-theme .mat-tree {
  background: white; }

.light-theme .mat-tree-node {
  color: rgba(0, 0, 0, 0.87); }

.light-theme .mat-snack-bar-container {
  background: #323232;
  color: white; }

.light-theme .mat-simple-snackbar-action {
  color: black; }

.dark-theme, .light-theme {
  /*
.mat-datepicker-content-touch .mat-calendar {
      width: 64vh !important;
      height: 80vh !important;
  }
*/
  /*
.mat-calendar-header{
    background-color: $gold-color;

}

.mat-calendar-body-today:not(.mat-calendar-body-selected),.my-theme .mat-calendar-body-today:not(.mat-calendar-body-selected){
  background-color: grey!important;
  color: white;
  border: 0px !important;
   color: white !important;
    font-weight: bold !important;
    border: 0px !important;
}



.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected){
  background-color: $gold-color !important;
  color: white !important;
}
*/
  /**
 * Variables declared here can be overridden by consuming applications, with
 * the help of the `!default` flag.
 *
 * @example
 *     // overriding $hoverColor
 *     $hoverColor: rgba(red, 0.05);
 *
 *     // overriding image path
 *     $flagsImagePath: "images/";
 *
 *     // import the scss file after the overrides
 */ }
  .dark-theme .mat-elevation-z0, .light-theme .mat-elevation-z0 {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z1, .light-theme .mat-elevation-z1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z2, .light-theme .mat-elevation-z2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z3, .light-theme .mat-elevation-z3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z4, .light-theme .mat-elevation-z4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z5, .light-theme .mat-elevation-z5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z6, .light-theme .mat-elevation-z6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z7, .light-theme .mat-elevation-z7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z8, .light-theme .mat-elevation-z8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z9, .light-theme .mat-elevation-z9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z10, .light-theme .mat-elevation-z10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z11, .light-theme .mat-elevation-z11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z12, .light-theme .mat-elevation-z12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z13, .light-theme .mat-elevation-z13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z14, .light-theme .mat-elevation-z14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z15, .light-theme .mat-elevation-z15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z16, .light-theme .mat-elevation-z16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z17, .light-theme .mat-elevation-z17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z18, .light-theme .mat-elevation-z18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z19, .light-theme .mat-elevation-z19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z20, .light-theme .mat-elevation-z20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z21, .light-theme .mat-elevation-z21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z22, .light-theme .mat-elevation-z22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z23, .light-theme .mat-elevation-z23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-elevation-z24, .light-theme .mat-elevation-z24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }
  .dark-theme .mat-badge-content, .light-theme .mat-badge-content {
    font-weight: 600;
    font-size: 12px;
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-badge-small .mat-badge-content, .light-theme .mat-badge-small .mat-badge-content {
    font-size: 6px; }
  .dark-theme .mat-badge-large .mat-badge-content, .light-theme .mat-badge-large .mat-badge-content {
    font-size: 24px; }
  .dark-theme .mat-h1, .dark-theme .mat-headline, .dark-theme .mat-typography h1, .light-theme .mat-h1, .light-theme .mat-headline, .light-theme .mat-typography h1 {
    font: 400 24px/32px "Raleway", sans-serif;;
    margin: 0 0 16px; }
  .dark-theme .mat-h2, .dark-theme .mat-title, .dark-theme .mat-typography h2, .light-theme .mat-h2, .light-theme .mat-title, .light-theme .mat-typography h2 {
    font: 500 20px/32px "Raleway", sans-serif;;
    margin: 0 0 16px; }
  .dark-theme .mat-h3, .dark-theme .mat-subheading-2, .dark-theme .mat-typography h3, .light-theme .mat-h3, .light-theme .mat-subheading-2, .light-theme .mat-typography h3 {
    font: 400 16px/28px "Raleway", sans-serif;;
    margin: 0 0 16px; }
  .dark-theme .mat-h4, .dark-theme .mat-subheading-1, .dark-theme .mat-typography h4, .light-theme .mat-h4, .light-theme .mat-subheading-1, .light-theme .mat-typography h4 {
    font: 400 15px/24px "Raleway", sans-serif;;
    margin: 0 0 16px; }
  .dark-theme .mat-h5, .dark-theme .mat-typography h5, .light-theme .mat-h5, .light-theme .mat-typography h5 {
    font: 400 11.62px/20px "Raleway", sans-serif;;
    margin: 0 0 12px; }
  .dark-theme .mat-h6, .dark-theme .mat-typography h6, .light-theme .mat-h6, .light-theme .mat-typography h6 {
    font: 400 9.38px/20px "Raleway", sans-serif;;
    margin: 0 0 12px; }
  .dark-theme .mat-body-strong, .dark-theme .mat-body-2, .light-theme .mat-body-strong, .light-theme .mat-body-2 {
    font: 500 14px/24px "Raleway", sans-serif;; }
  .dark-theme .mat-body, .dark-theme .mat-body-1, .dark-theme .mat-typography, .light-theme .mat-body, .light-theme .mat-body-1, .light-theme .mat-typography {
    font: 400 14px/20px "Raleway", sans-serif;; }
    .dark-theme .mat-body p, .dark-theme .mat-body-1 p, .dark-theme .mat-typography p, .light-theme .mat-body p, .light-theme .mat-body-1 p, .light-theme .mat-typography p {
      margin: 0 0 12px; }
  .dark-theme .mat-small, .dark-theme .mat-caption, .light-theme .mat-small, .light-theme .mat-caption {
    font: 400 12px/20px "Raleway", sans-serif;; }
  .dark-theme .mat-display-4, .dark-theme .mat-typography .mat-display-4, .light-theme .mat-display-4, .light-theme .mat-typography .mat-display-4 {
    font: 300 112px/112px "Raleway", sans-serif;;
    margin: 0 0 56px;
    letter-spacing: -0.05em; }
  .dark-theme .mat-display-3, .dark-theme .mat-typography .mat-display-3, .light-theme .mat-display-3, .light-theme .mat-typography .mat-display-3 {
    font: 400 56px/56px "Raleway", sans-serif;;
    margin: 0 0 64px;
    letter-spacing: -0.02em; }
  .dark-theme .mat-display-2, .dark-theme .mat-typography .mat-display-2, .light-theme .mat-display-2, .light-theme .mat-typography .mat-display-2 {
    font: 400 45px/48px "Raleway", sans-serif;;
    margin: 0 0 64px;
    letter-spacing: -0.005em; }
  .dark-theme .mat-display-1, .dark-theme .mat-typography .mat-display-1, .light-theme .mat-display-1, .light-theme .mat-typography .mat-display-1 {
    font: 400 34px/40px "Raleway", sans-serif;;
    margin: 0 0 64px; }
  .dark-theme .mat-bottom-sheet-container, .light-theme .mat-bottom-sheet-container {
    font-family: "Raleway", sans-serif;;
    font-size: 16px;
    font-weight: 400; }
  .dark-theme .mat-button, .dark-theme .mat-raised-button, .dark-theme .mat-icon-button, .dark-theme .mat-stroked-button,
  .dark-theme .mat-flat-button, .dark-theme .mat-fab, .dark-theme .mat-mini-fab, .light-theme .mat-button, .light-theme .mat-raised-button, .light-theme .mat-icon-button, .light-theme .mat-stroked-button,
  .light-theme .mat-flat-button, .light-theme .mat-fab, .light-theme .mat-mini-fab {
    font-family: "Raleway", sans-serif;;
    font-size: 14px;
    font-weight: 500; }
  .dark-theme .mat-button-toggle, .light-theme .mat-button-toggle {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-card, .light-theme .mat-card {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-card-title, .light-theme .mat-card-title {
    font-size: 24px;
    font-weight: 400; }
  .dark-theme .mat-card-subtitle,
  .dark-theme .mat-card-content,
  .dark-theme .mat-card-header .mat-card-title, .light-theme .mat-card-subtitle,
  .light-theme .mat-card-content,
  .light-theme .mat-card-header .mat-card-title {
    font-size: 14px; }
  .dark-theme .mat-checkbox, .light-theme .mat-checkbox {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-checkbox-layout .mat-checkbox-label, .light-theme .mat-checkbox-layout .mat-checkbox-label {
    line-height: 24px; }
  .dark-theme .mat-chip, .light-theme .mat-chip {
    font-size: 13px;
    line-height: 18px; }
    .dark-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
    .dark-theme .mat-chip .mat-chip-remove.mat-icon, .light-theme .mat-chip .mat-chip-trailing-icon.mat-icon,
    .light-theme .mat-chip .mat-chip-remove.mat-icon {
      font-size: 18px; }
  .dark-theme .mat-table, .light-theme .mat-table {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-header-cell, .light-theme .mat-header-cell {
    font-size: 12px;
    font-weight: 500; }
  .dark-theme .mat-cell, .dark-theme .mat-footer-cell, .light-theme .mat-cell, .light-theme .mat-footer-cell {
    font-size: 14px; }
  .dark-theme .mat-calendar, .light-theme .mat-calendar {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-calendar-body, .light-theme .mat-calendar-body {
    font-size: 13px; }
  .dark-theme .mat-calendar-body-label,
  .dark-theme .mat-calendar-period-button, .light-theme .mat-calendar-body-label,
  .light-theme .mat-calendar-period-button {
    font-size: 14px;
    font-weight: 500; }
  .dark-theme .mat-calendar-table-header th, .light-theme .mat-calendar-table-header th {
    font-size: 11px;
    font-weight: 400; }
  .dark-theme .mat-dialog-title, .light-theme .mat-dialog-title {
    font: 500 20px/32px "Raleway", sans-serif;; }
  .dark-theme .mat-expansion-panel-header, .light-theme .mat-expansion-panel-header {
    font-family: "Raleway", sans-serif;;
    font-size: 15px;
    font-weight: 400; }
  .dark-theme .mat-expansion-panel-content, .light-theme .mat-expansion-panel-content {
    font: 400 14px/20px "Raleway", sans-serif;; }
  .dark-theme .mat-form-field, .light-theme .mat-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-form-field-wrapper, .light-theme .mat-form-field-wrapper {
    padding-bottom: 1.34375em; }
  .dark-theme .mat-form-field-prefix .mat-icon,
  .dark-theme .mat-form-field-suffix .mat-icon, .light-theme .mat-form-field-prefix .mat-icon,
  .light-theme .mat-form-field-suffix .mat-icon {
    font-size: 150%;
    line-height: 1.125; }
  .dark-theme .mat-form-field-prefix .mat-icon-button,
  .dark-theme .mat-form-field-suffix .mat-icon-button, .light-theme .mat-form-field-prefix .mat-icon-button,
  .light-theme .mat-form-field-suffix .mat-icon-button {
    height: 1.5em;
    width: 1.5em; }
    .dark-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
    .dark-theme .mat-form-field-suffix .mat-icon-button .mat-icon, .light-theme .mat-form-field-prefix .mat-icon-button .mat-icon,
    .light-theme .mat-form-field-suffix .mat-icon-button .mat-icon {
      height: 1.125em;
      line-height: 1.125; }
  .dark-theme .mat-form-field-infix, .light-theme .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 0.84375em solid transparent; }
  .dark-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .dark-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .light-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .light-theme .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.34375em) scale(0.75);
    width: 133.33333%; }
  .dark-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label, .light-theme .mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.34374em) scale(0.75);
    width: 133.33334%; }
  .dark-theme .mat-form-field-label-wrapper, .light-theme .mat-form-field-label-wrapper {
    top: -0.84375em;
    padding-top: 0.84375em; }
  .dark-theme .mat-form-field-label, .light-theme .mat-form-field-label {
    top: 1.34375em; }
  .dark-theme .mat-form-field-underline, .light-theme .mat-form-field-underline {
    bottom: 1.34375em; }
  .dark-theme .mat-form-field-subscript-wrapper, .light-theme .mat-form-field-subscript-wrapper {
    font-size: 75%;
    margin-top: 0.66667em;
    top: calc(100% - 1.79167em); }
  .dark-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper, .light-theme .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.25em; }
  .dark-theme .mat-form-field-appearance-legacy .mat-form-field-infix, .light-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.4375em 0; }
  .dark-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .dark-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .light-theme .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .light-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    -ms-transform: translateY(-1.28125em) scale(0.75);
    width: 133.33333%; }
  .dark-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label, .light-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
    -ms-transform: translateY(-1.28124em) scale(0.75);
    width: 133.33334%; }
  .dark-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label, .light-theme .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
    -ms-transform: translateY(-1.28123em) scale(0.75);
    width: 133.33335%; }
  .dark-theme .mat-form-field-appearance-legacy .mat-form-field-label, .light-theme .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.28125em; }
  .dark-theme .mat-form-field-appearance-legacy .mat-form-field-underline, .light-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 1.25em; }
  .dark-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper, .light-theme .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 0.54167em;
    top: calc(100% - 1.66667em); }
  .dark-theme .mat-form-field-appearance-fill .mat-form-field-infix, .light-theme .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0.25em 0 0.75em 0; }
  .dark-theme .mat-form-field-appearance-fill .mat-form-field-label, .light-theme .mat-form-field-appearance-fill .mat-form-field-label {
    top: 1.09375em;
    margin-top: -0.5em; }
  .dark-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .dark-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .light-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .light-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-0.59375em) scale(0.75);
    width: 133.33333%; }
  .dark-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label, .light-theme .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-0.59374em) scale(0.75);
    width: 133.33334%; }
  .dark-theme .mat-form-field-appearance-outline .mat-form-field-infix, .light-theme .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em 0 1em 0; }
  .dark-theme .mat-form-field-appearance-outline .mat-form-field-label, .light-theme .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.84375em;
    margin-top: -0.25em; }
  .dark-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .dark-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .light-theme .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .light-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.59375em) scale(0.75);
    width: 133.33333%; }
  .dark-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label, .light-theme .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper
.mat-form-field-label {
    transform: translateY(-1.59374em) scale(0.75);
    width: 133.33334%; }
  .dark-theme .mat-grid-tile-header,
  .dark-theme .mat-grid-tile-footer, .light-theme .mat-grid-tile-header,
  .light-theme .mat-grid-tile-footer {
    font-size: 14px; }
    .dark-theme .mat-grid-tile-header .mat-line,
    .dark-theme .mat-grid-tile-footer .mat-line, .light-theme .mat-grid-tile-header .mat-line,
    .light-theme .mat-grid-tile-footer .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box; }
      .dark-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
      .dark-theme .mat-grid-tile-footer .mat-line:nth-child(n+2), .light-theme .mat-grid-tile-header .mat-line:nth-child(n+2),
      .light-theme .mat-grid-tile-footer .mat-line:nth-child(n+2) {
        font-size: 12px; }
  .dark-theme input.mat-input-element, .light-theme input.mat-input-element {
    margin-top: -0.0625em; }
  .dark-theme .mat-menu-item, .light-theme .mat-menu-item {
    font-family: "Raleway", sans-serif;;
    font-size: 16px;
    font-weight: 400; }
  .dark-theme .mat-paginator,
  .dark-theme .mat-paginator-page-size .mat-select-trigger, .light-theme .mat-paginator,
  .light-theme .mat-paginator-page-size .mat-select-trigger {
    font-family: "Raleway", sans-serif;;
    font-size: 12px; }
  .dark-theme .mat-radio-button, .light-theme .mat-radio-button {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-select, .light-theme .mat-select {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-select-trigger, .light-theme .mat-select-trigger {
    height: 1.125em; }
  .dark-theme .mat-slide-toggle-content, .light-theme .mat-slide-toggle-content {
    font: 400 14px/20px "Raleway", sans-serif;; }
  .dark-theme .mat-slider-thumb-label-text, .light-theme .mat-slider-thumb-label-text {
    font-family: "Raleway", sans-serif;;
    font-size: 12px;
    font-weight: 500; }
  .dark-theme .mat-stepper-vertical, .dark-theme .mat-stepper-horizontal, .light-theme .mat-stepper-vertical, .light-theme .mat-stepper-horizontal {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-step-label, .light-theme .mat-step-label {
    font-size: 14px;
    font-weight: 400; }
  .dark-theme .mat-step-label-selected, .light-theme .mat-step-label-selected {
    font-size: 14px;
    font-weight: 500; }
  .dark-theme .mat-tab-group, .light-theme .mat-tab-group {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-tab-label, .dark-theme .mat-tab-link, .light-theme .mat-tab-label, .light-theme .mat-tab-link {
    font-family: "Raleway", sans-serif;;
    font-size: 14px;
    font-weight: 500; }
  .dark-theme .mat-toolbar,
  .dark-theme .mat-toolbar h1,
  .dark-theme .mat-toolbar h2,
  .dark-theme .mat-toolbar h3,
  .dark-theme .mat-toolbar h4,
  .dark-theme .mat-toolbar h5,
  .dark-theme .mat-toolbar h6, .light-theme .mat-toolbar,
  .light-theme .mat-toolbar h1,
  .light-theme .mat-toolbar h2,
  .light-theme .mat-toolbar h3,
  .light-theme .mat-toolbar h4,
  .light-theme .mat-toolbar h5,
  .light-theme .mat-toolbar h6 {
    font: 500 20px/32px "Raleway", sans-serif;;
    margin: 0; }
  .dark-theme .mat-tooltip, .light-theme .mat-tooltip {
    font-family: "Raleway", sans-serif;;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px; }
  .dark-theme .mat-tooltip-handset, .light-theme .mat-tooltip-handset {
    font-size: 14px;
    padding-top: 9px;
    padding-bottom: 9px; }
  .dark-theme .mat-list-item, .light-theme .mat-list-item {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-list-option, .light-theme .mat-list-option {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-list .mat-list-item, .dark-theme .mat-nav-list .mat-list-item, .dark-theme .mat-selection-list .mat-list-item, .light-theme .mat-list .mat-list-item, .light-theme .mat-nav-list .mat-list-item, .light-theme .mat-selection-list .mat-list-item {
    font-size: 16px; }
    .dark-theme .mat-list .mat-list-item .mat-line, .dark-theme .mat-nav-list .mat-list-item .mat-line, .dark-theme .mat-selection-list .mat-list-item .mat-line, .light-theme .mat-list .mat-list-item .mat-line, .light-theme .mat-nav-list .mat-list-item .mat-line, .light-theme .mat-selection-list .mat-list-item .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box; }
      .dark-theme .mat-list .mat-list-item .mat-line:nth-child(n+2), .dark-theme .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .dark-theme .mat-selection-list .mat-list-item .mat-line:nth-child(n+2), .light-theme .mat-list .mat-list-item .mat-line:nth-child(n+2), .light-theme .mat-nav-list .mat-list-item .mat-line:nth-child(n+2), .light-theme .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
        font-size: 14px; }
  .dark-theme .mat-list .mat-list-option, .dark-theme .mat-nav-list .mat-list-option, .dark-theme .mat-selection-list .mat-list-option, .light-theme .mat-list .mat-list-option, .light-theme .mat-nav-list .mat-list-option, .light-theme .mat-selection-list .mat-list-option {
    font-size: 16px; }
    .dark-theme .mat-list .mat-list-option .mat-line, .dark-theme .mat-nav-list .mat-list-option .mat-line, .dark-theme .mat-selection-list .mat-list-option .mat-line, .light-theme .mat-list .mat-list-option .mat-line, .light-theme .mat-nav-list .mat-list-option .mat-line, .light-theme .mat-selection-list .mat-list-option .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box; }
      .dark-theme .mat-list .mat-list-option .mat-line:nth-child(n+2), .dark-theme .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .dark-theme .mat-selection-list .mat-list-option .mat-line:nth-child(n+2), .light-theme .mat-list .mat-list-option .mat-line:nth-child(n+2), .light-theme .mat-nav-list .mat-list-option .mat-line:nth-child(n+2), .light-theme .mat-selection-list .mat-list-option .mat-line:nth-child(n+2) {
        font-size: 14px; }
  .dark-theme .mat-list .mat-subheader, .dark-theme .mat-nav-list .mat-subheader, .dark-theme .mat-selection-list .mat-subheader, .light-theme .mat-list .mat-subheader, .light-theme .mat-nav-list .mat-subheader, .light-theme .mat-selection-list .mat-subheader {
    font-family: "Raleway", sans-serif;;
    font-size: 14px;
    font-weight: 500; }
  .dark-theme .mat-list[dense] .mat-list-item, .dark-theme .mat-nav-list[dense] .mat-list-item, .dark-theme .mat-selection-list[dense] .mat-list-item, .light-theme .mat-list[dense] .mat-list-item, .light-theme .mat-nav-list[dense] .mat-list-item, .light-theme .mat-selection-list[dense] .mat-list-item {
    font-size: 12px; }
    .dark-theme .mat-list[dense] .mat-list-item .mat-line, .dark-theme .mat-nav-list[dense] .mat-list-item .mat-line, .dark-theme .mat-selection-list[dense] .mat-list-item .mat-line, .light-theme .mat-list[dense] .mat-list-item .mat-line, .light-theme .mat-nav-list[dense] .mat-list-item .mat-line, .light-theme .mat-selection-list[dense] .mat-list-item .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box; }
      .dark-theme .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .dark-theme .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .dark-theme .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2), .light-theme .mat-list[dense] .mat-list-item .mat-line:nth-child(n+2), .light-theme .mat-nav-list[dense] .mat-list-item .mat-line:nth-child(n+2), .light-theme .mat-selection-list[dense] .mat-list-item .mat-line:nth-child(n+2) {
        font-size: 12px; }
  .dark-theme .mat-list[dense] .mat-list-option, .dark-theme .mat-nav-list[dense] .mat-list-option, .dark-theme .mat-selection-list[dense] .mat-list-option, .light-theme .mat-list[dense] .mat-list-option, .light-theme .mat-nav-list[dense] .mat-list-option, .light-theme .mat-selection-list[dense] .mat-list-option {
    font-size: 12px; }
    .dark-theme .mat-list[dense] .mat-list-option .mat-line, .dark-theme .mat-nav-list[dense] .mat-list-option .mat-line, .dark-theme .mat-selection-list[dense] .mat-list-option .mat-line, .light-theme .mat-list[dense] .mat-list-option .mat-line, .light-theme .mat-nav-list[dense] .mat-list-option .mat-line, .light-theme .mat-selection-list[dense] .mat-list-option .mat-line {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      box-sizing: border-box; }
      .dark-theme .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .dark-theme .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .dark-theme .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2), .light-theme .mat-list[dense] .mat-list-option .mat-line:nth-child(n+2), .light-theme .mat-nav-list[dense] .mat-list-option .mat-line:nth-child(n+2), .light-theme .mat-selection-list[dense] .mat-list-option .mat-line:nth-child(n+2) {
        font-size: 12px; }
  .dark-theme .mat-list[dense] .mat-subheader, .dark-theme .mat-nav-list[dense] .mat-subheader, .dark-theme .mat-selection-list[dense] .mat-subheader, .light-theme .mat-list[dense] .mat-subheader, .light-theme .mat-nav-list[dense] .mat-subheader, .light-theme .mat-selection-list[dense] .mat-subheader {
    font-family: "Raleway", sans-serif;;
    font-size: 12px;
    font-weight: 500; }
  .dark-theme .mat-option, .light-theme .mat-option {
    font-family: "Raleway", sans-serif;;
    font-size: 16px; }
  .dark-theme .mat-optgroup-label, .light-theme .mat-optgroup-label {
    font: 500 14px/24px "Raleway", sans-serif;; }
  .dark-theme .mat-simple-snackbar, .light-theme .mat-simple-snackbar {
    font-family: "Raleway", sans-serif;;
    font-size: 14px; }
  .dark-theme .mat-simple-snackbar-action, .light-theme .mat-simple-snackbar-action {
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500; }
  .dark-theme .mat-tree, .light-theme .mat-tree {
    font-family: "Raleway", sans-serif;; }
  .dark-theme .mat-tree-node, .light-theme .mat-tree-node {
    font-weight: 400;
    font-size: 14px; }
  .dark-theme .mat-ripple, .light-theme .mat-ripple {
    overflow: hidden; }
  .dark-theme .mat-ripple.mat-ripple-unbounded, .light-theme .mat-ripple.mat-ripple-unbounded {
    overflow: visible; }
  .dark-theme .mat-ripple-element, .light-theme .mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0); }
    @media screen and (-ms-high-contrast: active) {
      .dark-theme .mat-ripple-element, .light-theme .mat-ripple-element {
        display: none; } }
  .dark-theme .cdk-visually-hidden, .light-theme .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none; }
  .dark-theme .cdk-overlay-container, .dark-theme .cdk-global-overlay-wrapper, .light-theme .cdk-overlay-container, .light-theme .cdk-global-overlay-wrapper {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .dark-theme .cdk-overlay-container, .light-theme .cdk-overlay-container {
    position: fixed;
    z-index: 1000; }
    .dark-theme .cdk-overlay-container:empty, .light-theme .cdk-overlay-container:empty {
      display: none; }
  .dark-theme .cdk-global-overlay-wrapper, .light-theme .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000; }
  .dark-theme .cdk-overlay-pane, .light-theme .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%; }
  .dark-theme .cdk-overlay-backdrop, .light-theme .cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 0; }
    .dark-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing, .light-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 1; }
      @media screen and (-ms-high-contrast: active) {
        .dark-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing, .light-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
          opacity: 0.6; } }
  .dark-theme .cdk-overlay-dark-backdrop, .light-theme .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.288); }
  .dark-theme .cdk-overlay-transparent-backdrop, .dark-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing, .light-theme .cdk-overlay-transparent-backdrop, .light-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0; }
  .dark-theme .cdk-overlay-connected-position-bounding-box, .light-theme .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px; }
  .dark-theme .cdk-global-scrollblock, .light-theme .cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll; }

@keyframes cdk-text-field-autofill-start {
  /*!*/ }

@keyframes cdk-text-field-autofill-end {
  /*!*/ }
  .dark-theme .cdk-text-field-autofill-monitored:-webkit-autofill, .light-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
    animation-name: cdk-text-field-autofill-start; }
  .dark-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill), .light-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation-name: cdk-text-field-autofill-end; }
  .dark-theme textarea.cdk-textarea-autosize, .light-theme textarea.cdk-textarea-autosize {
    resize: none; }
  .dark-theme textarea.cdk-textarea-autosize-measuring, .light-theme textarea.cdk-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    padding: 2px 0 !important;
    box-sizing: content-box !important; }
  .dark-theme .timepicker__header, .light-theme .timepicker__header {
    background-color: #424242 !important; }
  .dark-theme .clock-face__number > span.active, .light-theme .clock-face__number > span.active {
    background-color: #424242 !important;
    color: #caaa58 !important; }
  .dark-theme .clock-face__clock-hand, .light-theme .clock-face__clock-hand {
    background-color: #424242 !important; }
    .dark-theme .clock-face__clock-hand:after, .light-theme .clock-face__clock-hand:after {
      background-color: #424242 !important; }
    .dark-theme .clock-face__clock-hand:before, .light-theme .clock-face__clock-hand:before {
      border-color: #424242 !important; }
  .dark-theme .timepicker-dial__item_active, .light-theme .timepicker-dial__item_active {
    color: #caaa58 !important; }
  .dark-theme .timepicker-button, .light-theme .timepicker-button {
    color: #424242 !important; }
  @media (min-width: 1025px) {
    .dark-theme .mat-datepicker-content-touch .mat-calendar, .light-theme .mat-datepicker-content-touch .mat-calendar {
      width: 50vh !important;
      height: 60vh !important; } }
  @media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
    .dark-theme .mat-datepicker-content-touch .mat-calendar, .light-theme .mat-datepicker-content-touch .mat-calendar {
      width: 64vh !important;
      height: 80vh !important; } }
  .dark-theme timepicker.ng-touched.ng-invalid .input-date, .dark-theme datepicker.ng-touched.ng-invalid .input-date, .light-theme timepicker.ng-touched.ng-invalid .input-date, .light-theme datepicker.ng-touched.ng-invalid .input-date {
    border-color: red;
    border-left: 1px solid #ccc; }
  .dark-theme timepicker.ng-touched.ng-invalid .input-icon, .dark-theme datepicker.ng-touched.ng-invalid .input-icon, .light-theme timepicker.ng-touched.ng-invalid .input-icon, .light-theme datepicker.ng-touched.ng-invalid .input-icon {
    border-color: red; }
  .dark-theme .mat-calendar, .dark-theme .mat-button, .light-theme .mat-calendar, .light-theme .mat-button {
    font-family: Roboto,sans-serif; }
  .dark-theme .full-width, .light-theme .full-width {
    width: 100%; }
  .dark-theme .dark-theme .mat-form-field-appearance-legacy .mat-form-field-label, .light-theme .dark-theme .mat-form-field-appearance-legacy .mat-form-field-label {
    color: white; }
  .dark-theme .dark-theme .mat-form-field-appearance-legacy .mat-form-field-infix, .light-theme .dark-theme .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding-top: 15px; }
  .dark-theme .mat-form-field-label-wrapper label, .light-theme .mat-form-field-label-wrapper label {
    margin-bottom: 5px;
    font-weight: bold; }
  .dark-theme .selectdemoBasicUsage md-input-container, .light-theme .selectdemoBasicUsage md-input-container {
    margin-right: 10px; }
  .dark-theme mat-form-field label, .light-theme mat-form-field label {
    margin-bottom: 5px;
    font-weight: bold; }
  .dark-theme .mat-option, .dark-theme .mat-select-value-text, .light-theme .mat-option, .light-theme .mat-select-value-text {
    font-family: sans-serif; }
  .dark-theme .intl-tel-input, .light-theme .intl-tel-input {
    position: relative;
    display: inline-block;
    font-family: Roboto, sans-serif; }
  .dark-theme .intl-tel-input *, .light-theme .intl-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .dark-theme .intl-tel-input .hide, .light-theme .intl-tel-input .hide {
    display: none; }
  .dark-theme .intl-tel-input .v-hide, .light-theme .intl-tel-input .v-hide {
    visibility: hidden; }
  .dark-theme .intl-tel-input input, .dark-theme .intl-tel-input input[type=text], .dark-theme .intl-tel-input input[type=tel], .light-theme .intl-tel-input input, .light-theme .intl-tel-input input[type=text], .light-theme .intl-tel-input input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0; }
  .dark-theme .intl-tel-input .flag-container, .light-theme .intl-tel-input .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px; }
  .dark-theme .intl-tel-input .selected-flag, .light-theme .intl-tel-input .selected-flag {
    z-index: 1;
    position: relative;
    top: 17px;
    width: 36px;
    height: 32px;
    padding: 0 0 0 8px; }
  .dark-theme .intl-tel-input .selected-flag .iti-flag, .light-theme .intl-tel-input .selected-flag .iti-flag {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }
  .dark-theme .intl-tel-input .selected-flag .iti-arrow, .light-theme .intl-tel-input .selected-flag .iti-arrow {
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555; }
  .dark-theme .intl-tel-input .selected-flag .iti-arrow.up, .light-theme .intl-tel-input .selected-flag .iti-arrow.up {
    border-top: none;
    border-bottom: 4px solid #555; }
  .dark-theme .intl-tel-input .country-list, .light-theme .intl-tel-input .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: -22px 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #CCC;
    white-space: nowrap;
    max-height: 300px;
    min-width: 250px;
    overflow-y: scroll; }
  .dark-theme .intl-tel-input .country-list.dropup, .light-theme .intl-tel-input .country-list.dropup {
    bottom: 100%;
    margin-bottom: -1px; }
  .dark-theme .intl-tel-input .country-list .flag-box, .light-theme .intl-tel-input .country-list .flag-box {
    display: inline-block;
    width: 20px; }
  @media (max-width: 500px) {
    .dark-theme .intl-tel-input .country-list, .light-theme .intl-tel-input .country-list {
      white-space: normal; } }
  .dark-theme .intl-tel-input .country-list .divider, .light-theme .intl-tel-input .country-list .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    background-color: transparent;
    margin-top: 0;
    border-bottom: 1px solid #CCC; }
  .dark-theme .intl-tel-input .country-list .country, .light-theme .intl-tel-input .country-list .country {
    padding: 5px 10px; }
  .dark-theme .intl-tel-input .country-list .country .dial-code, .light-theme .intl-tel-input .country-list .country .dial-code {
    color: #999; }
  .dark-theme .intl-tel-input .country-list .country.highlight, .light-theme .intl-tel-input .country-list .country.highlight {
    background-color: rgba(0, 0, 0, 0.05); }
  .dark-theme .intl-tel-input .country-list .flag-box, .dark-theme .intl-tel-input .country-list .country-name, .dark-theme .intl-tel-input .country-list .dial-code, .light-theme .intl-tel-input .country-list .flag-box, .light-theme .intl-tel-input .country-list .country-name, .light-theme .intl-tel-input .country-list .dial-code {
    vertical-align: middle; }
  .dark-theme .intl-tel-input .country-list .flag-box, .dark-theme .intl-tel-input .country-list .country-name, .light-theme .intl-tel-input .country-list .flag-box, .light-theme .intl-tel-input .country-list .country-name {
    margin-right: 6px; }
  .dark-theme .intl-tel-input.allow-dropdown input, .dark-theme .intl-tel-input.allow-dropdown input[type=text], .dark-theme .intl-tel-input.allow-dropdown input[type=tel], .dark-theme .intl-tel-input.separate-dial-code input, .dark-theme .intl-tel-input.separate-dial-code input[type=text], .dark-theme .intl-tel-input.separate-dial-code input[type=tel], .light-theme .intl-tel-input.allow-dropdown input, .light-theme .intl-tel-input.allow-dropdown input[type=text], .light-theme .intl-tel-input.allow-dropdown input[type=tel], .light-theme .intl-tel-input.separate-dial-code input, .light-theme .intl-tel-input.separate-dial-code input[type=text], .light-theme .intl-tel-input.separate-dial-code input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0; }
  .dark-theme .intl-tel-input.allow-dropdown .flag-container, .dark-theme .intl-tel-input.separate-dial-code .flag-container, .light-theme .intl-tel-input.allow-dropdown .flag-container, .light-theme .intl-tel-input.separate-dial-code .flag-container {
    right: auto;
    left: 0; }
  .dark-theme .intl-tel-input.allow-dropdown .selected-flag, .dark-theme .intl-tel-input.separate-dial-code .selected-flag, .light-theme .intl-tel-input.allow-dropdown .selected-flag, .light-theme .intl-tel-input.separate-dial-code .selected-flag {
    width: 46px; }
  .dark-theme .intl-tel-input.allow-dropdown .flag-container:hover, .light-theme .intl-tel-input.allow-dropdown .flag-container:hover {
    cursor: pointer; }
  .dark-theme .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag, .light-theme .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    background-color: rgba(0, 0, 0, 0.05); }
  .dark-theme .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .dark-theme .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover, .light-theme .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .light-theme .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
    cursor: default; }
  .dark-theme .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .dark-theme .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag, .light-theme .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .light-theme .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
    background-color: transparent; }
  .dark-theme .intl-tel-input.separate-dial-code .selected-flag, .light-theme .intl-tel-input.separate-dial-code .selected-flag {
    background-color: rgba(0, 0, 0, 0.05);
    display: table; }
  .dark-theme .intl-tel-input.separate-dial-code .selected-dial-code, .light-theme .intl-tel-input.separate-dial-code .selected-dial-code {
    display: table-cell;
    vertical-align: middle;
    padding-left: 28px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-2 input, .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-2 input, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel] {
    padding-left: 66px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
    width: 60px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input, .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
    padding-left: 76px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
    width: 70px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-3 input, .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-3 input, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel] {
    padding-left: 74px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
    width: 68px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
    padding-left: 84px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
    width: 78px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-4 input, .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-4 input, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel] {
    padding-left: 82px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
    width: 76px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
    padding-left: 92px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
    width: 86px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-5 input, .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-5 input, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text], .light-theme .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel] {
    padding-left: 90px; }
  .dark-theme .intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
    width: 84px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input, .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text], .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text], .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
    padding-left: 100px; }
  .dark-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag, .light-theme .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
    width: 94px; }
  .dark-theme .intl-tel-input.iti-container, .light-theme .intl-tel-input.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px; }
  .dark-theme .intl-tel-input.iti-container:hover, .light-theme .intl-tel-input.iti-container:hover {
    cursor: pointer; }
  .dark-theme .iti-mobile .intl-tel-input.iti-container, .light-theme .iti-mobile .intl-tel-input.iti-container {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed; }
  .dark-theme .iti-mobile .intl-tel-input .country-list, .light-theme .iti-mobile .intl-tel-input .country-list {
    max-height: 100%;
    width: 100%; }
  .dark-theme .iti-mobile .intl-tel-input .country-list .country, .light-theme .iti-mobile .intl-tel-input .country-list .country {
    padding: 10px 10px;
    line-height: 1.5em; }
  .dark-theme .iti-flag, .light-theme .iti-flag {
    width: 20px; }
  .dark-theme .iti-flag.be, .light-theme .iti-flag.be {
    width: 18px; }
  .dark-theme .iti-flag.ch, .light-theme .iti-flag.ch {
    width: 15px; }
  .dark-theme .iti-flag.mc, .light-theme .iti-flag.mc {
    width: 19px; }
  .dark-theme .iti-flag.ne, .light-theme .iti-flag.ne {
    width: 18px; }
  .dark-theme .iti-flag.np, .light-theme .iti-flag.np {
    width: 13px; }
  .dark-theme .iti-flag.va, .light-theme .iti-flag.va {
    width: 15px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .dark-theme .iti-flag, .light-theme .iti-flag {
      background-size: 5630px 15px; } }
  .dark-theme .iti-flag.ac, .light-theme .iti-flag.ac {
    height: 10px;
    background-position: 0px 0px; }
  .dark-theme .iti-flag.ad, .light-theme .iti-flag.ad {
    height: 14px;
    background-position: -22px 0px; }
  .dark-theme .iti-flag.ae, .light-theme .iti-flag.ae {
    height: 10px;
    background-position: -44px 0px; }
  .dark-theme .iti-flag.af, .light-theme .iti-flag.af {
    height: 14px;
    background-position: -66px 0px; }
  .dark-theme .iti-flag.ag, .light-theme .iti-flag.ag {
    height: 14px;
    background-position: -88px 0px; }
  .dark-theme .iti-flag.ai, .light-theme .iti-flag.ai {
    height: 10px;
    background-position: -110px 0px; }
  .dark-theme .iti-flag.al, .light-theme .iti-flag.al {
    height: 15px;
    background-position: -132px 0px; }
  .dark-theme .iti-flag.am, .light-theme .iti-flag.am {
    height: 10px;
    background-position: -154px 0px; }
  .dark-theme .iti-flag.ao, .light-theme .iti-flag.ao {
    height: 14px;
    background-position: -176px 0px; }
  .dark-theme .iti-flag.aq, .light-theme .iti-flag.aq {
    height: 14px;
    background-position: -198px 0px; }
  .dark-theme .iti-flag.ar, .light-theme .iti-flag.ar {
    height: 13px;
    background-position: -220px 0px; }
  .dark-theme .iti-flag.as, .light-theme .iti-flag.as {
    height: 10px;
    background-position: -242px 0px; }
  .dark-theme .iti-flag.at, .light-theme .iti-flag.at {
    height: 14px;
    background-position: -264px 0px; }
  .dark-theme .iti-flag.au, .light-theme .iti-flag.au {
    height: 10px;
    background-position: -286px 0px; }
  .dark-theme .iti-flag.aw, .light-theme .iti-flag.aw {
    height: 14px;
    background-position: -308px 0px; }
  .dark-theme .iti-flag.ax, .light-theme .iti-flag.ax {
    height: 13px;
    background-position: -330px 0px; }
  .dark-theme .iti-flag.az, .light-theme .iti-flag.az {
    height: 10px;
    background-position: -352px 0px; }
  .dark-theme .iti-flag.ba, .light-theme .iti-flag.ba {
    height: 10px;
    background-position: -374px 0px; }
  .dark-theme .iti-flag.bb, .light-theme .iti-flag.bb {
    height: 14px;
    background-position: -396px 0px; }
  .dark-theme .iti-flag.bd, .light-theme .iti-flag.bd {
    height: 12px;
    background-position: -418px 0px; }
  .dark-theme .iti-flag.be, .light-theme .iti-flag.be {
    height: 15px;
    background-position: -440px 0px; }
  .dark-theme .iti-flag.bf, .light-theme .iti-flag.bf {
    height: 14px;
    background-position: -460px 0px; }
  .dark-theme .iti-flag.bg, .light-theme .iti-flag.bg {
    height: 12px;
    background-position: -482px 0px; }
  .dark-theme .iti-flag.bh, .light-theme .iti-flag.bh {
    height: 12px;
    background-position: -504px 0px; }
  .dark-theme .iti-flag.bi, .light-theme .iti-flag.bi {
    height: 12px;
    background-position: -526px 0px; }
  .dark-theme .iti-flag.bj, .light-theme .iti-flag.bj {
    height: 14px;
    background-position: -548px 0px; }
  .dark-theme .iti-flag.bl, .light-theme .iti-flag.bl {
    height: 14px;
    background-position: -570px 0px; }
  .dark-theme .iti-flag.bm, .light-theme .iti-flag.bm {
    height: 10px;
    background-position: -592px 0px; }
  .dark-theme .iti-flag.bn, .light-theme .iti-flag.bn {
    height: 10px;
    background-position: -614px 0px; }
  .dark-theme .iti-flag.bo, .light-theme .iti-flag.bo {
    height: 14px;
    background-position: -636px 0px; }
  .dark-theme .iti-flag.bq, .light-theme .iti-flag.bq {
    height: 14px;
    background-position: -658px 0px; }
  .dark-theme .iti-flag.br, .light-theme .iti-flag.br {
    height: 14px;
    background-position: -680px 0px; }
  .dark-theme .iti-flag.bs, .light-theme .iti-flag.bs {
    height: 10px;
    background-position: -702px 0px; }
  .dark-theme .iti-flag.bt, .light-theme .iti-flag.bt {
    height: 14px;
    background-position: -724px 0px; }
  .dark-theme .iti-flag.bv, .light-theme .iti-flag.bv {
    height: 15px;
    background-position: -746px 0px; }
  .dark-theme .iti-flag.bw, .light-theme .iti-flag.bw {
    height: 14px;
    background-position: -768px 0px; }
  .dark-theme .iti-flag.by, .light-theme .iti-flag.by {
    height: 10px;
    background-position: -790px 0px; }
  .dark-theme .iti-flag.bz, .light-theme .iti-flag.bz {
    height: 14px;
    background-position: -812px 0px; }
  .dark-theme .iti-flag.ca, .light-theme .iti-flag.ca {
    height: 10px;
    background-position: -834px 0px; }
  .dark-theme .iti-flag.cc, .light-theme .iti-flag.cc {
    height: 10px;
    background-position: -856px 0px; }
  .dark-theme .iti-flag.cd, .light-theme .iti-flag.cd {
    height: 15px;
    background-position: -878px 0px; }
  .dark-theme .iti-flag.cf, .light-theme .iti-flag.cf {
    height: 14px;
    background-position: -900px 0px; }
  .dark-theme .iti-flag.cg, .light-theme .iti-flag.cg {
    height: 14px;
    background-position: -922px 0px; }
  .dark-theme .iti-flag.ch, .light-theme .iti-flag.ch {
    height: 15px;
    background-position: -944px 0px; }
  .dark-theme .iti-flag.ci, .light-theme .iti-flag.ci {
    height: 14px;
    background-position: -961px 0px; }
  .dark-theme .iti-flag.ck, .light-theme .iti-flag.ck {
    height: 10px;
    background-position: -983px 0px; }
  .dark-theme .iti-flag.cl, .light-theme .iti-flag.cl {
    height: 14px;
    background-position: -1005px 0px; }
  .dark-theme .iti-flag.cm, .light-theme .iti-flag.cm {
    height: 14px;
    background-position: -1027px 0px; }
  .dark-theme .iti-flag.cn, .light-theme .iti-flag.cn {
    height: 14px;
    background-position: -1049px 0px; }
  .dark-theme .iti-flag.co, .light-theme .iti-flag.co {
    height: 14px;
    background-position: -1071px 0px; }
  .dark-theme .iti-flag.cp, .light-theme .iti-flag.cp {
    height: 14px;
    background-position: -1093px 0px; }
  .dark-theme .iti-flag.cr, .light-theme .iti-flag.cr {
    height: 12px;
    background-position: -1115px 0px; }
  .dark-theme .iti-flag.cu, .light-theme .iti-flag.cu {
    height: 10px;
    background-position: -1137px 0px; }
  .dark-theme .iti-flag.cv, .light-theme .iti-flag.cv {
    height: 12px;
    background-position: -1159px 0px; }
  .dark-theme .iti-flag.cw, .light-theme .iti-flag.cw {
    height: 14px;
    background-position: -1181px 0px; }
  .dark-theme .iti-flag.cx, .light-theme .iti-flag.cx {
    height: 10px;
    background-position: -1203px 0px; }
  .dark-theme .iti-flag.cy, .light-theme .iti-flag.cy {
    height: 13px;
    background-position: -1225px 0px; }
  .dark-theme .iti-flag.cz, .light-theme .iti-flag.cz {
    height: 14px;
    background-position: -1247px 0px; }
  .dark-theme .iti-flag.de, .light-theme .iti-flag.de {
    height: 12px;
    background-position: -1269px 0px; }
  .dark-theme .iti-flag.dg, .light-theme .iti-flag.dg {
    height: 10px;
    background-position: -1291px 0px; }
  .dark-theme .iti-flag.dj, .light-theme .iti-flag.dj {
    height: 14px;
    background-position: -1313px 0px; }
  .dark-theme .iti-flag.dk, .light-theme .iti-flag.dk {
    height: 15px;
    background-position: -1335px 0px; }
  .dark-theme .iti-flag.dm, .light-theme .iti-flag.dm {
    height: 10px;
    background-position: -1357px 0px; }
  .dark-theme .iti-flag.do, .light-theme .iti-flag.do {
    height: 13px;
    background-position: -1379px 0px; }
  .dark-theme .iti-flag.dz, .light-theme .iti-flag.dz {
    height: 14px;
    background-position: -1401px 0px; }
  .dark-theme .iti-flag.ea, .light-theme .iti-flag.ea {
    height: 14px;
    background-position: -1423px 0px; }
  .dark-theme .iti-flag.ec, .light-theme .iti-flag.ec {
    height: 14px;
    background-position: -1445px 0px; }
  .dark-theme .iti-flag.ee, .light-theme .iti-flag.ee {
    height: 13px;
    background-position: -1467px 0px; }
  .dark-theme .iti-flag.eg, .light-theme .iti-flag.eg {
    height: 14px;
    background-position: -1489px 0px; }
  .dark-theme .iti-flag.eh, .light-theme .iti-flag.eh {
    height: 10px;
    background-position: -1511px 0px; }
  .dark-theme .iti-flag.er, .light-theme .iti-flag.er {
    height: 10px;
    background-position: -1533px 0px; }
  .dark-theme .iti-flag.es, .light-theme .iti-flag.es {
    height: 14px;
    background-position: -1555px 0px; }
  .dark-theme .iti-flag.et, .light-theme .iti-flag.et {
    height: 10px;
    background-position: -1577px 0px; }
  .dark-theme .iti-flag.eu, .light-theme .iti-flag.eu {
    height: 14px;
    background-position: -1599px 0px; }
  .dark-theme .iti-flag.fi, .light-theme .iti-flag.fi {
    height: 12px;
    background-position: -1621px 0px; }
  .dark-theme .iti-flag.fj, .light-theme .iti-flag.fj {
    height: 10px;
    background-position: -1643px 0px; }
  .dark-theme .iti-flag.fk, .light-theme .iti-flag.fk {
    height: 10px;
    background-position: -1665px 0px; }
  .dark-theme .iti-flag.fm, .light-theme .iti-flag.fm {
    height: 11px;
    background-position: -1687px 0px; }
  .dark-theme .iti-flag.fo, .light-theme .iti-flag.fo {
    height: 15px;
    background-position: -1709px 0px; }
  .dark-theme .iti-flag.fr, .light-theme .iti-flag.fr {
    height: 14px;
    background-position: -1731px 0px; }
  .dark-theme .iti-flag.ga, .light-theme .iti-flag.ga {
    height: 15px;
    background-position: -1753px 0px; }
  .dark-theme .iti-flag.gb, .light-theme .iti-flag.gb {
    height: 10px;
    background-position: -1775px 0px; }
  .dark-theme .iti-flag.gd, .light-theme .iti-flag.gd {
    height: 12px;
    background-position: -1797px 0px; }
  .dark-theme .iti-flag.ge, .light-theme .iti-flag.ge {
    height: 14px;
    background-position: -1819px 0px; }
  .dark-theme .iti-flag.gf, .light-theme .iti-flag.gf {
    height: 14px;
    background-position: -1841px 0px; }
  .dark-theme .iti-flag.gg, .light-theme .iti-flag.gg {
    height: 14px;
    background-position: -1863px 0px; }
  .dark-theme .iti-flag.gh, .light-theme .iti-flag.gh {
    height: 14px;
    background-position: -1885px 0px; }
  .dark-theme .iti-flag.gi, .light-theme .iti-flag.gi {
    height: 10px;
    background-position: -1907px 0px; }
  .dark-theme .iti-flag.gl, .light-theme .iti-flag.gl {
    height: 14px;
    background-position: -1929px 0px; }
  .dark-theme .iti-flag.gm, .light-theme .iti-flag.gm {
    height: 14px;
    background-position: -1951px 0px; }
  .dark-theme .iti-flag.gn, .light-theme .iti-flag.gn {
    height: 14px;
    background-position: -1973px 0px; }
  .dark-theme .iti-flag.gp, .light-theme .iti-flag.gp {
    height: 14px;
    background-position: -1995px 0px; }
  .dark-theme .iti-flag.gq, .light-theme .iti-flag.gq {
    height: 14px;
    background-position: -2017px 0px; }
  .dark-theme .iti-flag.gr, .light-theme .iti-flag.gr {
    height: 14px;
    background-position: -2039px 0px; }
  .dark-theme .iti-flag.gs, .light-theme .iti-flag.gs {
    height: 10px;
    background-position: -2061px 0px; }
  .dark-theme .iti-flag.gt, .light-theme .iti-flag.gt {
    height: 13px;
    background-position: -2083px 0px; }
  .dark-theme .iti-flag.gu, .light-theme .iti-flag.gu {
    height: 11px;
    background-position: -2105px 0px; }
  .dark-theme .iti-flag.gw, .light-theme .iti-flag.gw {
    height: 10px;
    background-position: -2127px 0px; }
  .dark-theme .iti-flag.gy, .light-theme .iti-flag.gy {
    height: 12px;
    background-position: -2149px 0px; }
  .dark-theme .iti-flag.hk, .light-theme .iti-flag.hk {
    height: 14px;
    background-position: -2171px 0px; }
  .dark-theme .iti-flag.hm, .light-theme .iti-flag.hm {
    height: 10px;
    background-position: -2193px 0px; }
  .dark-theme .iti-flag.hn, .light-theme .iti-flag.hn {
    height: 10px;
    background-position: -2215px 0px; }
  .dark-theme .iti-flag.hr, .light-theme .iti-flag.hr {
    height: 10px;
    background-position: -2237px 0px; }
  .dark-theme .iti-flag.ht, .light-theme .iti-flag.ht {
    height: 12px;
    background-position: -2259px 0px; }
  .dark-theme .iti-flag.hu, .light-theme .iti-flag.hu {
    height: 10px;
    background-position: -2281px 0px; }
  .dark-theme .iti-flag.ic, .light-theme .iti-flag.ic {
    height: 14px;
    background-position: -2303px 0px; }
  .dark-theme .iti-flag.id, .light-theme .iti-flag.id {
    height: 14px;
    background-position: -2325px 0px; }
  .dark-theme .iti-flag.ie, .light-theme .iti-flag.ie {
    height: 10px;
    background-position: -2347px 0px; }
  .dark-theme .iti-flag.il, .light-theme .iti-flag.il {
    height: 15px;
    background-position: -2369px 0px; }
  .dark-theme .iti-flag.im, .light-theme .iti-flag.im {
    height: 10px;
    background-position: -2391px 0px; }
  .dark-theme .iti-flag.in, .light-theme .iti-flag.in {
    height: 14px;
    background-position: -2413px 0px; }
  .dark-theme .iti-flag.io, .light-theme .iti-flag.io {
    height: 10px;
    background-position: -2435px 0px; }
  .dark-theme .iti-flag.iq, .light-theme .iti-flag.iq {
    height: 14px;
    background-position: -2457px 0px; }
  .dark-theme .iti-flag.ir, .light-theme .iti-flag.ir {
    height: 12px;
    background-position: -2479px 0px; }
  .dark-theme .iti-flag.is, .light-theme .iti-flag.is {
    height: 15px;
    background-position: -2501px 0px; }
  .dark-theme .iti-flag.it, .light-theme .iti-flag.it {
    height: 14px;
    background-position: -2523px 0px; }
  .dark-theme .iti-flag.je, .light-theme .iti-flag.je {
    height: 12px;
    background-position: -2545px 0px; }
  .dark-theme .iti-flag.jm, .light-theme .iti-flag.jm {
    height: 10px;
    background-position: -2567px 0px; }
  .dark-theme .iti-flag.jo, .light-theme .iti-flag.jo {
    height: 10px;
    background-position: -2589px 0px; }
  .dark-theme .iti-flag.jp, .light-theme .iti-flag.jp {
    height: 14px;
    background-position: -2611px 0px; }
  .dark-theme .iti-flag.ke, .light-theme .iti-flag.ke {
    height: 14px;
    background-position: -2633px 0px; }
  .dark-theme .iti-flag.kg, .light-theme .iti-flag.kg {
    height: 12px;
    background-position: -2655px 0px; }
  .dark-theme .iti-flag.kh, .light-theme .iti-flag.kh {
    height: 13px;
    background-position: -2677px 0px; }
  .dark-theme .iti-flag.ki, .light-theme .iti-flag.ki {
    height: 10px;
    background-position: -2699px 0px; }
  .dark-theme .iti-flag.km, .light-theme .iti-flag.km {
    height: 12px;
    background-position: -2721px 0px; }
  .dark-theme .iti-flag.kn, .light-theme .iti-flag.kn {
    height: 14px;
    background-position: -2743px 0px; }
  .dark-theme .iti-flag.kp, .light-theme .iti-flag.kp {
    height: 10px;
    background-position: -2765px 0px; }
  .dark-theme .iti-flag.kr, .light-theme .iti-flag.kr {
    height: 14px;
    background-position: -2787px 0px; }
  .dark-theme .iti-flag.kw, .light-theme .iti-flag.kw {
    height: 10px;
    background-position: -2809px 0px; }
  .dark-theme .iti-flag.ky, .light-theme .iti-flag.ky {
    height: 10px;
    background-position: -2831px 0px; }
  .dark-theme .iti-flag.kz, .light-theme .iti-flag.kz {
    height: 10px;
    background-position: -2853px 0px; }
  .dark-theme .iti-flag.la, .light-theme .iti-flag.la {
    height: 14px;
    background-position: -2875px 0px; }
  .dark-theme .iti-flag.lb, .light-theme .iti-flag.lb {
    height: 14px;
    background-position: -2897px 0px; }
  .dark-theme .iti-flag.lc, .light-theme .iti-flag.lc {
    height: 10px;
    background-position: -2919px 0px; }
  .dark-theme .iti-flag.li, .light-theme .iti-flag.li {
    height: 12px;
    background-position: -2941px 0px; }
  .dark-theme .iti-flag.lk, .light-theme .iti-flag.lk {
    height: 10px;
    background-position: -2963px 0px; }
  .dark-theme .iti-flag.lr, .light-theme .iti-flag.lr {
    height: 11px;
    background-position: -2985px 0px; }
  .dark-theme .iti-flag.ls, .light-theme .iti-flag.ls {
    height: 14px;
    background-position: -3007px 0px; }
  .dark-theme .iti-flag.lt, .light-theme .iti-flag.lt {
    height: 12px;
    background-position: -3029px 0px; }
  .dark-theme .iti-flag.lu, .light-theme .iti-flag.lu {
    height: 12px;
    background-position: -3051px 0px; }
  .dark-theme .iti-flag.lv, .light-theme .iti-flag.lv {
    height: 10px;
    background-position: -3073px 0px; }
  .dark-theme .iti-flag.ly, .light-theme .iti-flag.ly {
    height: 10px;
    background-position: -3095px 0px; }
  .dark-theme .iti-flag.ma, .light-theme .iti-flag.ma {
    height: 14px;
    background-position: -3117px 0px; }
  .dark-theme .iti-flag.mc, .light-theme .iti-flag.mc {
    height: 15px;
    background-position: -3139px 0px; }
  .dark-theme .iti-flag.md, .light-theme .iti-flag.md {
    height: 10px;
    background-position: -3160px 0px; }
  .dark-theme .iti-flag.me, .light-theme .iti-flag.me {
    height: 10px;
    background-position: -3182px 0px; }
  .dark-theme .iti-flag.mf, .light-theme .iti-flag.mf {
    height: 14px;
    background-position: -3204px 0px; }
  .dark-theme .iti-flag.mg, .light-theme .iti-flag.mg {
    height: 14px;
    background-position: -3226px 0px; }
  .dark-theme .iti-flag.mh, .light-theme .iti-flag.mh {
    height: 11px;
    background-position: -3248px 0px; }
  .dark-theme .iti-flag.mk, .light-theme .iti-flag.mk {
    height: 10px;
    background-position: -3270px 0px; }
  .dark-theme .iti-flag.ml, .light-theme .iti-flag.ml {
    height: 14px;
    background-position: -3292px 0px; }
  .dark-theme .iti-flag.mm, .light-theme .iti-flag.mm {
    height: 14px;
    background-position: -3314px 0px; }
  .dark-theme .iti-flag.mn, .light-theme .iti-flag.mn {
    height: 10px;
    background-position: -3336px 0px; }
  .dark-theme .iti-flag.mo, .light-theme .iti-flag.mo {
    height: 14px;
    background-position: -3358px 0px; }
  .dark-theme .iti-flag.mp, .light-theme .iti-flag.mp {
    height: 10px;
    background-position: -3380px 0px; }
  .dark-theme .iti-flag.mq, .light-theme .iti-flag.mq {
    height: 14px;
    background-position: -3402px 0px; }
  .dark-theme .iti-flag.mr, .light-theme .iti-flag.mr {
    height: 14px;
    background-position: -3424px 0px; }
  .dark-theme .iti-flag.ms, .light-theme .iti-flag.ms {
    height: 10px;
    background-position: -3446px 0px; }
  .dark-theme .iti-flag.mt, .light-theme .iti-flag.mt {
    height: 14px;
    background-position: -3468px 0px; }
  .dark-theme .iti-flag.mu, .light-theme .iti-flag.mu {
    height: 14px;
    background-position: -3490px 0px; }
  .dark-theme .iti-flag.mv, .light-theme .iti-flag.mv {
    height: 14px;
    background-position: -3512px 0px; }
  .dark-theme .iti-flag.mw, .light-theme .iti-flag.mw {
    height: 14px;
    background-position: -3534px 0px; }
  .dark-theme .iti-flag.mx, .light-theme .iti-flag.mx {
    height: 12px;
    background-position: -3556px 0px; }
  .dark-theme .iti-flag.my, .light-theme .iti-flag.my {
    height: 10px;
    background-position: -3578px 0px; }
  .dark-theme .iti-flag.mz, .light-theme .iti-flag.mz {
    height: 14px;
    background-position: -3600px 0px; }
  .dark-theme .iti-flag.na, .light-theme .iti-flag.na {
    height: 14px;
    background-position: -3622px 0px; }
  .dark-theme .iti-flag.nc, .light-theme .iti-flag.nc {
    height: 10px;
    background-position: -3644px 0px; }
  .dark-theme .iti-flag.ne, .light-theme .iti-flag.ne {
    height: 15px;
    background-position: -3666px 0px; }
  .dark-theme .iti-flag.nf, .light-theme .iti-flag.nf {
    height: 10px;
    background-position: -3686px 0px; }
  .dark-theme .iti-flag.ng, .light-theme .iti-flag.ng {
    height: 10px;
    background-position: -3708px 0px; }
  .dark-theme .iti-flag.ni, .light-theme .iti-flag.ni {
    height: 12px;
    background-position: -3730px 0px; }
  .dark-theme .iti-flag.nl, .light-theme .iti-flag.nl {
    height: 14px;
    background-position: -3752px 0px; }
  .dark-theme .iti-flag.no, .light-theme .iti-flag.no {
    height: 15px;
    background-position: -3774px 0px; }
  .dark-theme .iti-flag.np, .light-theme .iti-flag.np {
    height: 15px;
    background-position: -3796px 0px; }
  .dark-theme .iti-flag.nr, .light-theme .iti-flag.nr {
    height: 10px;
    background-position: -3811px 0px; }
  .dark-theme .iti-flag.nu, .light-theme .iti-flag.nu {
    height: 10px;
    background-position: -3833px 0px; }
  .dark-theme .iti-flag.nz, .light-theme .iti-flag.nz {
    height: 10px;
    background-position: -3855px 0px; }
  .dark-theme .iti-flag.om, .light-theme .iti-flag.om {
    height: 10px;
    background-position: -3877px 0px; }
  .dark-theme .iti-flag.pa, .light-theme .iti-flag.pa {
    height: 14px;
    background-position: -3899px 0px; }
  .dark-theme .iti-flag.pe, .light-theme .iti-flag.pe {
    height: 14px;
    background-position: -3921px 0px; }
  .dark-theme .iti-flag.pf, .light-theme .iti-flag.pf {
    height: 14px;
    background-position: -3943px 0px; }
  .dark-theme .iti-flag.pg, .light-theme .iti-flag.pg {
    height: 15px;
    background-position: -3965px 0px; }
  .dark-theme .iti-flag.ph, .light-theme .iti-flag.ph {
    height: 10px;
    background-position: -3987px 0px; }
  .dark-theme .iti-flag.pk, .light-theme .iti-flag.pk {
    height: 14px;
    background-position: -4009px 0px; }
  .dark-theme .iti-flag.pl, .light-theme .iti-flag.pl {
    height: 13px;
    background-position: -4031px 0px; }
  .dark-theme .iti-flag.pm, .light-theme .iti-flag.pm {
    height: 14px;
    background-position: -4053px 0px; }
  .dark-theme .iti-flag.pn, .light-theme .iti-flag.pn {
    height: 10px;
    background-position: -4075px 0px; }
  .dark-theme .iti-flag.pr, .light-theme .iti-flag.pr {
    height: 14px;
    background-position: -4097px 0px; }
  .dark-theme .iti-flag.ps, .light-theme .iti-flag.ps {
    height: 10px;
    background-position: -4119px 0px; }
  .dark-theme .iti-flag.pt, .light-theme .iti-flag.pt {
    height: 14px;
    background-position: -4141px 0px; }
  .dark-theme .iti-flag.pw, .light-theme .iti-flag.pw {
    height: 13px;
    background-position: -4163px 0px; }
  .dark-theme .iti-flag.py, .light-theme .iti-flag.py {
    height: 11px;
    background-position: -4185px 0px; }
  .dark-theme .iti-flag.qa, .light-theme .iti-flag.qa {
    height: 8px;
    background-position: -4207px 0px; }
  .dark-theme .iti-flag.re, .light-theme .iti-flag.re {
    height: 14px;
    background-position: -4229px 0px; }
  .dark-theme .iti-flag.ro, .light-theme .iti-flag.ro {
    height: 14px;
    background-position: -4251px 0px; }
  .dark-theme .iti-flag.rs, .light-theme .iti-flag.rs {
    height: 14px;
    background-position: -4273px 0px; }
  .dark-theme .iti-flag.ru, .light-theme .iti-flag.ru {
    height: 14px;
    background-position: -4295px 0px; }
  .dark-theme .iti-flag.rw, .light-theme .iti-flag.rw {
    height: 14px;
    background-position: -4317px 0px; }
  .dark-theme .iti-flag.sa, .light-theme .iti-flag.sa {
    height: 14px;
    background-position: -4339px 0px; }
  .dark-theme .iti-flag.sb, .light-theme .iti-flag.sb {
    height: 10px;
    background-position: -4361px 0px; }
  .dark-theme .iti-flag.sc, .light-theme .iti-flag.sc {
    height: 10px;
    background-position: -4383px 0px; }
  .dark-theme .iti-flag.sd, .light-theme .iti-flag.sd {
    height: 10px;
    background-position: -4405px 0px; }
  .dark-theme .iti-flag.se, .light-theme .iti-flag.se {
    height: 13px;
    background-position: -4427px 0px; }
  .dark-theme .iti-flag.sg, .light-theme .iti-flag.sg {
    height: 14px;
    background-position: -4449px 0px; }
  .dark-theme .iti-flag.sh, .light-theme .iti-flag.sh {
    height: 10px;
    background-position: -4471px 0px; }
  .dark-theme .iti-flag.si, .light-theme .iti-flag.si {
    height: 10px;
    background-position: -4493px 0px; }
  .dark-theme .iti-flag.sj, .light-theme .iti-flag.sj {
    height: 15px;
    background-position: -4515px 0px; }
  .dark-theme .iti-flag.sk, .light-theme .iti-flag.sk {
    height: 14px;
    background-position: -4537px 0px; }
  .dark-theme .iti-flag.sl, .light-theme .iti-flag.sl {
    height: 14px;
    background-position: -4559px 0px; }
  .dark-theme .iti-flag.sm, .light-theme .iti-flag.sm {
    height: 15px;
    background-position: -4581px 0px; }
  .dark-theme .iti-flag.sn, .light-theme .iti-flag.sn {
    height: 14px;
    background-position: -4603px 0px; }
  .dark-theme .iti-flag.so, .light-theme .iti-flag.so {
    height: 14px;
    background-position: -4625px 0px; }
  .dark-theme .iti-flag.sr, .light-theme .iti-flag.sr {
    height: 14px;
    background-position: -4647px 0px; }
  .dark-theme .iti-flag.ss, .light-theme .iti-flag.ss {
    height: 10px;
    background-position: -4669px 0px; }
  .dark-theme .iti-flag.st, .light-theme .iti-flag.st {
    height: 10px;
    background-position: -4691px 0px; }
  .dark-theme .iti-flag.sv, .light-theme .iti-flag.sv {
    height: 12px;
    background-position: -4713px 0px; }
  .dark-theme .iti-flag.sx, .light-theme .iti-flag.sx {
    height: 14px;
    background-position: -4735px 0px; }
  .dark-theme .iti-flag.sy, .light-theme .iti-flag.sy {
    height: 14px;
    background-position: -4757px 0px; }
  .dark-theme .iti-flag.sz, .light-theme .iti-flag.sz {
    height: 14px;
    background-position: -4779px 0px; }
  .dark-theme .iti-flag.ta, .light-theme .iti-flag.ta {
    height: 10px;
    background-position: -4801px 0px; }
  .dark-theme .iti-flag.tc, .light-theme .iti-flag.tc {
    height: 10px;
    background-position: -4823px 0px; }
  .dark-theme .iti-flag.td, .light-theme .iti-flag.td {
    height: 14px;
    background-position: -4845px 0px; }
  .dark-theme .iti-flag.tf, .light-theme .iti-flag.tf {
    height: 14px;
    background-position: -4867px 0px; }
  .dark-theme .iti-flag.tg, .light-theme .iti-flag.tg {
    height: 13px;
    background-position: -4889px 0px; }
  .dark-theme .iti-flag.th, .light-theme .iti-flag.th {
    height: 14px;
    background-position: -4911px 0px; }
  .dark-theme .iti-flag.tj, .light-theme .iti-flag.tj {
    height: 10px;
    background-position: -4933px 0px; }
  .dark-theme .iti-flag.tk, .light-theme .iti-flag.tk {
    height: 10px;
    background-position: -4955px 0px; }
  .dark-theme .iti-flag.tl, .light-theme .iti-flag.tl {
    height: 10px;
    background-position: -4977px 0px; }
  .dark-theme .iti-flag.tm, .light-theme .iti-flag.tm {
    height: 14px;
    background-position: -4999px 0px; }
  .dark-theme .iti-flag.tn, .light-theme .iti-flag.tn {
    height: 14px;
    background-position: -5021px 0px; }
  .dark-theme .iti-flag.to, .light-theme .iti-flag.to {
    height: 10px;
    background-position: -5043px 0px; }
  .dark-theme .iti-flag.tr, .light-theme .iti-flag.tr {
    height: 14px;
    background-position: -5065px 0px; }
  .dark-theme .iti-flag.tt, .light-theme .iti-flag.tt {
    height: 12px;
    background-position: -5087px 0px; }
  .dark-theme .iti-flag.tv, .light-theme .iti-flag.tv {
    height: 10px;
    background-position: -5109px 0px; }
  .dark-theme .iti-flag.tw, .light-theme .iti-flag.tw {
    height: 14px;
    background-position: -5131px 0px; }
  .dark-theme .iti-flag.tz, .light-theme .iti-flag.tz {
    height: 14px;
    background-position: -5153px 0px; }
  .dark-theme .iti-flag.ua, .light-theme .iti-flag.ua {
    height: 14px;
    background-position: -5175px 0px; }
  .dark-theme .iti-flag.ug, .light-theme .iti-flag.ug {
    height: 14px;
    background-position: -5197px 0px; }
  .dark-theme .iti-flag.um, .light-theme .iti-flag.um {
    height: 11px;
    background-position: -5219px 0px; }
  .dark-theme .iti-flag.us, .light-theme .iti-flag.us {
    height: 11px;
    background-position: -5241px 0px; }
  .dark-theme .iti-flag.uy, .light-theme .iti-flag.uy {
    height: 14px;
    background-position: -5263px 0px; }
  .dark-theme .iti-flag.uz, .light-theme .iti-flag.uz {
    height: 10px;
    background-position: -5285px 0px; }
  .dark-theme .iti-flag.va, .light-theme .iti-flag.va {
    height: 15px;
    background-position: -5307px 0px; }
  .dark-theme .iti-flag.vc, .light-theme .iti-flag.vc {
    height: 14px;
    background-position: -5324px 0px; }
  .dark-theme .iti-flag.ve, .light-theme .iti-flag.ve {
    height: 14px;
    background-position: -5346px 0px; }
  .dark-theme .iti-flag.vg, .light-theme .iti-flag.vg {
    height: 10px;
    background-position: -5368px 0px; }
  .dark-theme .iti-flag.vi, .light-theme .iti-flag.vi {
    height: 14px;
    background-position: -5390px 0px; }
  .dark-theme .iti-flag.vn, .light-theme .iti-flag.vn {
    height: 14px;
    background-position: -5412px 0px; }
  .dark-theme .iti-flag.vu, .light-theme .iti-flag.vu {
    height: 12px;
    background-position: -5434px 0px; }
  .dark-theme .iti-flag.wf, .light-theme .iti-flag.wf {
    height: 14px;
    background-position: -5456px 0px; }
  .dark-theme .iti-flag.ws, .light-theme .iti-flag.ws {
    height: 10px;
    background-position: -5478px 0px; }
  .dark-theme .iti-flag.xk, .light-theme .iti-flag.xk {
    height: 15px;
    background-position: -5500px 0px; }
  .dark-theme .iti-flag.ye, .light-theme .iti-flag.ye {
    height: 14px;
    background-position: -5522px 0px; }
  .dark-theme .iti-flag.yt, .light-theme .iti-flag.yt {
    height: 14px;
    background-position: -5544px 0px; }
  .dark-theme .iti-flag.za, .light-theme .iti-flag.za {
    height: 14px;
    background-position: -5566px 0px; }
  .dark-theme .iti-flag.zm, .light-theme .iti-flag.zm {
    height: 14px;
    background-position: -5588px 0px; }
  .dark-theme .iti-flag.zw, .light-theme .iti-flag.zw {
    height: 10px;
    background-position: -5610px 0px; }
  .dark-theme .iti-flag, .light-theme .iti-flag {
    width: 20px;
    height: 15px;
    box-shadow: 0px 0px 1px 0px #888;
    background-image: url(https://www.rivieraelitedriver.com/static/dist/ae33acae404631e997ef8d91dae08ccd.png);
    background-repeat: no-repeat;
    background-color: #DBDBDB;
    background-position: 20px 0; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .dark-theme .iti-flag, .light-theme .iti-flag {
      background-image: url(https://www.rivieraelitedriver.com/static/dist/f2c77a6b7e26ff160fdb4193b383b1ea.png); } }
  .dark-theme .iti-flag.np, .light-theme .iti-flag.np {
    background-color: transparent; }
